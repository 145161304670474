<template>
<div v-if="alertOpen" class="global-banneralert-container hasSpace">
    <div class="global-banneralert d-flex">
        <div class="global-banner-text">
            {{ bannerInfo.bannerAlertText }}
            <a class="link" target="_blank" @click="pushEvent()" :href="openUrl">{{ bannerInfo.urlLabel }}</a>
        </div>
        <div class="close-button" @click="closeAlert()" tabindex="0">
            <img class="icon-close" src="@/assets/icons/icons-close-circle-secondary.png" />
        </div>
    </div>
</div>
</template>

<script>
import {
    EventBus
} from "@/event-bus.js";
import { globalEventConsts } from "@/constants.js";
import { useSfaStore } from "@/stores/sfa.js"	

export default {
    name: "GlobalBannerAlert",
    setup() {
        const sfaStore = useSfaStore();
        return { sfaStore };
    },
    data() {
        return {
            bannerInfo: {},
            openUrl: '',
            alertOpen: false,
            isSafeliteAgent: false,
            adminRole: ""
        }
    },
    watch: {
        "authState.idToken" : function(newIdToken){
            if (newIdToken) {
                this.adminRole = newIdToken.claims.dashboardUserRole;
                this.isSafeliteAgent = newIdToken.claims.dashboardUserRole?.includes("SFAD_Agent");
            }
        }
    },
    methods: {
        closeAlert() {
            if (this.sfaStore.globalBannerAlert == true) {
                this.alertOpen = false;
                this.sfaStore.globalBannerAlert = false;
                console.log(`GlobalBannerAlert.vue.closeAlert - this.alertOpen:${this.alertOpen}, this.sfaStore.globalBannerAlert:${this.sfaStore.globalBannerAlert}`);
                this.pushEventToGoogleDataLayer("global_banner", "banner_closed", this.bannerInfo.bannerAlertText, 0);
            }
        },
        pushEvent() {
            this.pushEventToGoogleDataLayer("global_banner", "banner_link_clicked", this.bannerInfo.url, 0);
        },
        checkIfGlobalBannerAlertShouldOpen() {
            if (this.isCurrentBanner && this.sfaStore.globalBannerAlert != false) {
                this.sfaStore.globalBannerAlert = true;
                this.alertOpen = true;
            } else {
                this.alertOpen = false;
            }
        },
        initializeBannerInfo(data) {
            this.bannerInfo = data;
            this.openUrl = this.bannerInfo.url?.startsWith("http://") || this.bannerInfo.url?.startsWith("https://") ? this.bannerInfo.url : `http://${this.bannerInfo.url}`;
            this.checkIfGlobalBannerAlertShouldOpen(); 
        }
    },
    computed: {
        isCurrentBanner() {
            return this.bannerInfo && this.bannerInfo.bannerAlertText != null && this.bannerInfo.bannerAlertText != "" && new Date(new Date(this.bannerInfo.startDateDisplay).setHours(0,0,0,0)) <= new Date(new Date().setHours(0,0,0,0)); 
        }
    },
    mounted() {
        EventBus.$on(globalEventConsts.GLOBAL_BANNER_ALERT_ADDED, (data) => {
            this.initializeBannerInfo(data);
        });        
    },
    created() {
        var self = this;
        
        self.sfaStore?.getCurrentBannerAsync({}).then((response) => {
            self.initializeBannerInfo(response)
        })
    }
}
</script>

<style lang="scss" scoped>
.hasSpace{
    margin-bottom:8px;
}
.global-banneralert-container {
    background-color: $blue;
    color: $grayscale-eight;
    font-weight: $font-weight-bold;

    .global-banneralert {
        max-width: $page-width-padded;
        padding: $spacer-three $page-padding;
        margin: auto;
        align-items: center;

        .global-banner-text {
            text-align: center;
            flex-grow: 1;

            .link {
                color: $grayscale-eight;
                text-decoration: underline;
                display: inline-block;
            }
        }

        .close-button {
            align-self: flex-start;
            margin-top: 2.5px;
            width: 20px;
            height: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    @include tablet {
        .global-banner-text {
            margin-right: $spacer-two;
        }
    }

    @include wide-mobile {
        .global-banner-text {
            margin-right: $spacer-three;
        }
    }
}
</style>
