<template>
<picture class="standard-header-logo-image" :class="{ cobranded: isCobrandedAgent }">
    <img :src="logo.src" :alt="logo.alt" />
</picture>
</template>

<script>

import { useSfaStore } from "@/stores/sfa.js"	
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
export default {
    name: "StandardHeaderLogoImage",
    setup() {
        const sfaStore = useSfaStore();
        return { sfaStore };
    },
    props: {
        isCobrandedAgent: Boolean,
    },
    data() {
        return {
            logo: {
                src: require("@/assets/logos/branding-logo-safelite-autoglass-desktop-new.svg"),
                alt: "Safelite For Agents"
            },
        }
    },
    mounted() {
        //Watch on the active logo to handle the change.
        const { activeLogo } = storeToRefs(this.sfaStore);
        var self = this;
        watch (activeLogo, (newValue, oldValue) => {
            self.logo = { ...newValue };
        });
    }
};
</script>

<style lang="scss">
.standard-header-logo-image {
    cursor: pointer;

    @include wide-mobile {
        
        display: flex;
        align-items: center;
        width:94px !important;

        &.cobranded {
            height: 50px;
        }
    }
}
.standard-header-logo-image.cobranded {
    width: 113px
}
</style>
