<template>
<div class="header-progress-bar">
    <div class="text">{{ progress }}%</div>
    <div class="progress-bar">
        <div class="progress-fill" :style="progressFillStyle">
        </div>
        <div class="progress-slider" :style="progressSliderStyle"></div>
    </div>
</div>
</template>

<script>
export default {
    name: "StandardHeaderProgressBar",
    props:{
      progress: Number,
    },
    computed: {
        progressFillStyle() {
            return `width: ${this.left + 7}px`
        },
        progressSliderStyle() {
            return `left: ${this.left}px;`;
        },
        left() {
            const screenWidth = window.screen.width;
            let sliderWidth = 0;
            let barWidth = 0;
            if (screenWidth <= 768) {
                sliderWidth = 10;
                barWidth = 95;
            } else {
                sliderWidth = 15;
                barWidth = 275;
            }

            const left = (barWidth - sliderWidth) / 100 * this.progress - 1;
            return left;
        }
    }
}
</script>

<style lang="scss" scoped>
.header-progress-bar {
    display: flex;
    align-items: center;

    .text {
        font-family: $font-family-primary;
        font-size: 14px;
        font-weight: 500;
        color: $red;
        line-height: 20px;
        letter-spacing: 0.75px;
        margin-right: $spacer-two;
    }
    .green-text {
         color:$green;
    }
    .progress-bar {
        border-radius: 8.5px;
        background-color: $grayscale-six;
        position: relative;

        .progress-fill {
            height: 15px;
            border-radius: 8.5px;
            background-color: $red;
            transition: width 0.2s linear;
        }

        .progress-slider {
            border: 2px solid $grayscale-eight;
            position: absolute;
            border-radius: 10px;
            top: -1px;
            background-color: $red;
            transition: left 0.2s linear;
        }
        .green {
            background-color: $green;
           
        }
    }

    @include tablet {
        margin-top: $spacer-three;
        margin-bottom: $spacer-five;

        .progress-bar {
            width: 275px;
            height: 15px;

            .progress-fill {
                height: 15px;
                min-width: 15px;
            }

            .progress-slider {
                width: 17px;
                height: 17px;
            }
        }
    }

    @include wide-mobile {

        .progress-bar {
            width: 95px;
            height: 10px;
            margin-right: $spacer-two;

            .progress-fill {
                height: 10px;
                min-width: 10px;
            }

            .progress-slider {
                width: 12px;
                height: 12px
            }
        }
    }
}
</style>
