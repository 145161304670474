<template>
<nav class="area-manager-panel">
    <h2 class="text-left text-md-center pb-5">Need help? <br class="d-initial d-md-none">Please get in touch.</h2>
    <div class="rectangle-line"></div>
    <div class="content-container">
        <div class="first-div mr-4">
            <h4>
                Your Account Manager
            </h4>
            <div v-if="Name !=''">
                <div class="detail">{{Name}}</div>
                <div class="detail">{{Phone}}</div>
                <a class="detail link d-block" :href="mailTo">{{Email}}</a>
            </div>
            <div v-else>
                 <div class="detail">No Account Manager has been assigned. Reach out to the Agency Care Desk for help.</div>
            </div>
        </div>
        <div class="second-div mr-4">
            <h4 class="pb-5">
                Agency Care Desk
            </h4>
            <div class="detail"><span class="field-label">Text:</span> {{this.textPhoneNumber}}</div>
            <div class="detail"><span class="field-label">Email:</span><a class="email link" href="mailto:agencycare@safelite.com"> agencycare@safelite.com</a></div>
            <!-- <a class="faq link" :href="checkUnauth">FAQs</a> -->
            <button class="faq link" @click="checkUnauth">FAQs</button>
            <div class="chat-now text-margin detail" :class="{ 'd-none': !isChatEnabled }"><a class="chat-now text-margin detail" @click="chatEmit('cta')">CHAT NOW</a>
                <figure class="chat-icon-container"><img class="chat-icon" @click="chatEmit('icon')" src="@/assets/icons/icon-chat.png" /></figure>
            </div>
        </div>
        <ModalWithButtons primaryButtonText="Yes, leave" secondaryButtonText="No, stay here" :openModal="isCancelModalOpen" @closing="showCancelModal(false, 'close')" @secondary-button-clicked="showCancelModal(false)" @primary-button-clicked="onCancelContinueClicked">
        <h4 class="cancel-title">Are you sure you want to leave this page?</h4>
        <p class="cancel-content pb-6">If you leave now, you won't be able to finish registering for this course unless you click the link again in the email sent to you.</p>
    </ModalWithButtons>
    </div>
</nav>
</template>

<script>
import ModalWithButtons from "@/sharedcomponents/ModalWithButtons.vue";
import { routeNameConstants } from '../../constants';
import {
    EventBus
} from '@/event-bus';
import {
    globalValueConsts,
    baseConsts
} from "@/constants.js";
import { useUserStore } from "@/stores/user.js"
import { useAgencyCarrierStore } from "@/stores/agencycarrier.js"

export default {
    name: "AreaManagerPanel",
    setup() {
        const userStore = useUserStore();
        const agencyCarrierStore = useAgencyCarrierStore();
        return { userStore, agencyCarrierStore };
    },
    props: {
        isChatEnabled: Boolean,
        pageName: String,
        isSafeliteAgent: String
    }, 
    emits : ['chat-button-clicked'],    
    components: {
        ModalWithButtons,
    },
    data: function () {
        return {
            AccountManagerDetails: {},
            Name: '',
            Phone: '',
            Email: '',
            mailTo: '',
            isCancelModalOpen: false,
        };
    },
    computed: {
        // Will be used once the number is set up to handle texts
        textPhoneNumber() {
            return globalValueConsts.TEXT_PHONE_NUMBER
        },
        faqLink() {
            return baseConsts.SFA_FAQ_LINK
        }
    },
    watch: {
        isSafeliteAgent() {
            return this.isSafeliteAgent;
        }
    },
    mounted() {    
        this.getManagerDetails();
    },
    methods: {
         showAreYouSureModal() {
            var courseID = sessionStorage.getItem("courseID");
            if (courseID != null) {
                if(this.$route.name==routeNameConstants.COURSE_REGISTRATION_UNAUTH 
                    || this.$route.name==routeNameConstants.SELECT_AGENCY_PAGE
                    ||this.$route.name==routeNameConstants.FIND_AGENCY_PAGE
                    || this.$route.name == routeNameConstants.AGENT_REGISTRATION
                    || this.$route.name == routeNameConstants.LOGIN_SCREEN) {
                        return true;
                    }
                }
            else {
                return false;
            }
        },
         showCancelModal(shouldShowCancelModal, btnLabel = null) {
            this.isCancelModalOpen = shouldShowCancelModal;
        },
        checkUnauth: function () {
            ;
            var self = this;
            if(this.showAreYouSureModal() == true)
            {
                this.showCancelModal(true);
            } else {
                
              window.location=baseConsts.SFA_FAQ_LINK;
            }
        },
        onCancelContinueClicked() {
            var self = this;     
            sessionStorage.removeItem("courseID");
            sessionStorage.removeItem("OktaID");     
            window.location=baseConsts.SFA_FAQ_LINK;           
        },
        faqLink1() {
            return baseConsts.SFA_FAQ_LINK
        },
        getManagerDetails() {
                var self = this;
                self.Name = '';
                self.agencyCarrierStore.getAndSetAccountManagerDetailsAsync({
                    accountNumber: self.userStore.activeAgencyNumber,
                    blocking: true
                }).then(function (response) {
                
                    self.AccountManagerDetails = response;
                    self.Name = self.AccountManagerDetails != '' ? self.AccountManagerDetails.FIRST_NAME?.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()) + ' ' + self.AccountManagerDetails.LAST_NAME?.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()) : '';
                    self.Phone = self.AccountManagerDetails?.PHONE;
                    self.Email = self.AccountManagerDetails?.EMAIL_ADDR;
                    self.mailTo = 'mailTo:' + self.AccountManagerDetails?.EMAIL_ADDR;
                });
            
        },
        chatEmit(type) {
            this.$emit('chat-button-clicked');
            var gaCategory = "agent_chat"
            if (this.isSafeliteAgent == "SFAD_Agent") {
                gaCategory = "agent_dashboard"
            } else if (this.isSafeliteAgent == "SFAD_Not_Agent") {
                gaCategory = "agent_admin_dashboard"
            }
            this.pushEventToGoogleDataLayer(gaCategory, "footer_chat_clicked", this.pageName, 0);
        }
    }
}
</script>

<style lang="scss" scoped>
.area-manager-panel {
    margin: auto;

    .rectangle-line {
        width: 120px;
        height: 5px;
        background-color: $red-primary;
        margin-bottom: $spacer-six;
    }

    .content-container {
        display: flex;

        &>div {
            flex-basis: 50%;

            .field-label {
                margin-bottom: 0;
                display: inline-block;
            }

            .detail {
                padding-bottom: $spacer-three;
            }
        }
    }

    @include tablet {
        padding: 40px $page-padding;
        max-width: $page-width-padded;

        .rectangle-line {
            margin-left: auto;
            margin-right: auto;
        }

        .content-container {
            h4 {
                padding-bottom: $spacer-five;
            }
        }
    }

    @include wide-mobile {
        width: 100%;
        padding: 40px $page-padding $spacer-six;

        .content-container {
            flex-direction: column;

            h4 {
                padding-bottom: $spacer-three;
            }

            .second-div {
                padding-top: $spacer-five;
            }
        }
    }
}
</style>
