const baseConsts = {
    DEBUG_ENABLED: process.env.VUE_APP_DEBUG_ENABLED,
    SAFELITE_COM_BASE_URL: process.env.VUE_APP_BASE_SAFELITEDOTCOM,
    DASHBOARD_BASE: '/dashboard',
    BASE_CONTENT_HUB: '/',
    CONTENT_HUB_BASE_URL: process.env.VUE_APP_CONTENT_SITE_BASE,
    SFA_FAQ_LINK:process.env.VUE_APP_FAQ,
    STORE_LOCATOR_BASE_URL: process.env.VUE_APP_STORE_LOCATOR_URL,
    AGENT_DEFAULT_GROUP_ID: process.env.VUE_APP_AGENT_DEFAULT_GROUP_ID,
    AGENT_RESET_PASSWORD_URI: process.env.VUE_APP_AGENT_RESET_PASSWORD_URI,
    APPLICATION_NAME: "Safelite For Agents",
    DASHBOARD_SERVICE_URI: process.env.VUE_APP_DASHBOARD_SERVICE_URI,
    CONTENT_SERVICE_URI: process.env.VUE_APP_CONTENT_SERVICE_URI,
    SHARED_SERVICE_URI: process.env.VUE_APP_SHARED_SERVICE_URI,
    DYNATRACE_URL: process.env.VUE_APP_DYNATRACE_URL,
    HEALTHCONFIG: process.env.VUE_APP_HEALTHCONFIG,
    PRIVACY_LINK: process.env.VUE_APP_PRIVACY_LINK,
    TERMS_LINK: process.env.VUE_APP_TERMS_LINK

}

const routeNameConstants = {
    SSO_REDIRECT: 'sso-redirect',
    FIND_AGENCY_PAGE: 'find-my-agency',
    SELECT_AGENCY_PAGE: 'select-agency',
    AGENCY_NOT_FOUND_PAGE: 'agency-not-found',
    AGENT_DASHBOARD: 'agent-dashboard',
    MANAGE_USERS: 'manage_users',
    LOGIN_SCREEN: 'login',
    FORGOT_PASSWORD: 'forgotpassword',
    HELP: 'help',
    AGENT_REGISTRATION: 'create-account',
    FILE_CLAIM: 'file-claim',
    CREATE_CE_COURSES: 'create-CE-course',
    START_CLAIM: 'start-claim',
    MANAGE_CLAIM: 'manage-claim',
    MANAGE_CECOURSES:'manage-cecourses',
    CE_COURSES_HOME: 'CE-Course-Home',
    CE_COURSES_UPCOMING: 'CE-Course-Upcoming',
    CE_MYCOURSES: 'CE-MyCourses',
    CE_COMPLETED_MYCOURSES: 'CE-Completed-MyCourses',
    WORDK_ORDER_DETAILS: 'work-order-details',
    AGENCY_ADMIN_ADD: 'agency-admin-add',
    USER_DETAILS: 'user-details',
    AGENT_REGISTRATION_SUCCESSFUL: 'registration-successful',
    POLICY_HOLDER_QUOTE: 'policy-holder-quote',
    DASHBOARD: 'dashboard',
    ADMIN_AGENT_DASHBOARD: 'admin-agent-dashboard',
    INTERNAL_ADMIN_DASHBOARD: 'internal-admin-dashboard',
    APPOINTMENT_OPTION_DETAILS: 'appointment-option-details',
    CREATE_NEW_PASSWORD: 'create-new-password',
    SUBMIT_CLAIM_REVIEW_DETAILS: 'submit-claim-review-details',
    SUCCESS_SUBMIT_CLAIM: 'success_submit-claim',
    CONTENT_SITE_HOME: 'Home',
    CONTENT_SITE_SEARCH_RESULTS: 'Search',
    CONTENT_SITE_TAGGED_ARTICLES_LIST: 'Tagged Articles',
    CONTENT_SITE_COURSE_REGISTRATION: 'CourseRegistration',
    CONTENT_SITE_AGENCY_CARE_DESK: 'Agency Care Desk',
    ORDER_SURVEY_DETAILS: 'order-survey-details',
    CONTENT_SITE_ARTICLE_DETAILS: 'article-details',
    COURSE_REGISTRATION: 'Course-Registration',
    COURSE_REGISTRATION_UNAUTH: 'Course-Registration-Unauth',
    NOT_FOUND_PAGE: 'not-found-page',
    PRODUCTION_MONITORING: 'production-monitoring',
    LINKEXPIRED_SCREEN: 'linkexpired',
};

const adminRequestStatus = {
    APPROVED : "Approved",
    DENIED : "Denied",
    PENDING : "Pending"
};

const pageConfigLayoutStyle = {
    ARTICLE_PAGE_WIDE_LAYOUT : "ArticlePageWideLayout"
}
const globalEventMsgConstants = {
    XYZ_COBRANDED_CARRIER_REMOVAL:"Due to edits in your carriers, you’re unable to file a claim. Please contact the <a name='Agency Care Desk' style='color:#FFFFFF;text-decoration: underline;cursor: pointer;font-weight: 500;' target:'_blank'>Agency Care Desk</a> for help.",
    XYZ_NONCOBRANDED_CARRIER_REMOVAL:"Due to edits in your carriers, you may not be able to file a claim. Please visit <a name='Manage my account' style='color:#FFFFFF;text-decoration: underline;cursor: pointer;font-weight: 500' target: '_blank'>Manage my account</a> to update your carrier information or call <a name='Agency Care Desk' style='color:#FFFFFF;text-decoration: underline;cursor: pointer;font-weight: 500;' target:'_blank'>Agency Care Desk</a> for help.",
    AGENCY_REMOVED: "Agency successfully removed"
} 

const errorMsgConstants = {
    RADIO_OPTION: {
        REQUIRED: "Please choose a scheduling option",
    },
    EMAIL: {
        REQUIRED: "Email address is required",
        MSG: "Please enter a valid email address"
    },
    PHONE_NUMBER: {
        REQUIRED: "The phone number field is required",
        MSG: "Please enter a valid phone number"
    },
    FIRST_NAME: {
        REQUIRED: "The first name field is required",
    },
    POLICY_NUMBER: {
        REQUIRED: "The policy number field is required",
    },
    LAST_NAME: {
        REQUIRED: "The last name field is required",
    },
    CARRIER: {
        REQUIRED: "The carrier field is required",
    },
    DEDUCTIBLE: {
        REQUIRED: "The deductible field is required",
    },
    ONLY_LETTERS: {
        MSG: "Only letters allow"
    },
    ONLY_DECIMAL_NUMBER_AMOUNT: {
        MSG: "Please enter a valid amount"
    },
    ZIP_CODE: {
        MSG: "Invalid ZIP. Please enter a new ZIP.",
        REQUIRED: "Please enter a valid ZIP code."
    },
    STATE: {
        REQUIRED: "Please choose a State."
    },
    DAMAGE_DATE_CHECK: {
        MSG: "Damage date must be before or equal to current date"
    },
    DAMAGE_DATE_OLD: {
        MSG: "Damage date must be within the past 10 years"
    },
    DAMAGE_DATE_REQD: {
        MSG: "Please enter a valid date."
    },
    ACCOUNT_NUMBER: {
        MSG: "Account number not found. Try another search"
    },
    BANNER_TITLE: {
        REQUIRED: "The banner title field is required",
    },
    BANNER_ALERT_TEXT: {
        REQUIRED: "The banner alert text field is required",
    },
    BANNER_URL_LABEL: {
        REQUIRED: "The url label field is required when url is entered",
    },
    BANNER_URL: {
        REQUIRED: "The url field is required when url label is entered",
        MSG: "Please enter a valid url"
    },
    BANNER_START_DATE: {
        REQUIRED: "The start date field is required",
    },
    BANNER_END_DATE: {
        REQUIRED: "The end date field is required",
        MSG: "The end date to be after the start date",
    },
    INVALID_AGENT_NUMBER: {
        MSG: "Invalid Agent Number",
    },
}

const apiPrefixConsts = {
    DASHBOARD: '/agentdashboard/api/v1/',
    CONTENT_SITE: '/api/v1/',
}


const workOrderStatusData = {
    CLOSED_ORDER_STATUS: [
        { name: "Job completed", value: 'job_completed' },
        { name: "Invoiced", value: 'invoiced' }],
    OPEN_ORDER_STATUS: [
        { name: "Open", value: 'Open' },
        { name: "Estimate", value: 'Estimate' },
        { name: "Not scheduled", value: 'Not scheduled' },
        { name: "Scheduled", value: 'Scheduled' },
        { name: "Routed", value: 'Routed' },
        { name: "In process ", value: 'In process' }]
}

const workOrderSearchProp = {
    ORDER_SEARCH_PROP: [
        { name: "Policyholder name", value: "policy_holder_name" },
        { name: "Agent name", value: "agent_name" },
        { name: "Status", value: 'status' },
        { name: "Submitted date", value: 'submitted_date' },
        { name: "Service date", value: 'service_date' },
        { name: "NPS", value: 'nps' }],
}

const adminRequestFilterProp = {
    REQUEST_FILTER_PROP: [
        { name: "Agent name", value: 'agent_name' },
        { name: "Agency name", value: 'agency_name' }],
}

const dashboardApiEndpointConsts = {
    GET_DASHBAORD: '/agentdashboard/api/v1/dashboard',
    VIEW_ERROR: '/agentdashboard/api/v1/ViewError/error',
    GET_AGENCIES: '/agentdashboard/api/v1/AgencySearch/GetAgencies',
    GET_AGENCY_SEARCH_STRINGS: '/agentdashboard/api/v1/AgencySearch/GetAllAgenciesForSearch',
    GET_STATES: '/agentdashboard/api/v1/States/GetStates',
    GET_CREATE_USER: '/agentdashboard/api/v1/okta/CreateInactiveUser',
    POST_FORGOTPASSWORD: '/agentdashboard/api/v1/okta/SendRecoveryPasswordLink',
    GET_CHECKUSER: '/agentdashboard/api/v1/okta/User/Verify/',
    GET_COMPLETED_WORK_ORDERS: '/agentdashboard/api/v1/AgencyWorkOrder/GetCompletedWorkOrders',
    GET_OPEN_WORK_ORDERS: '/agentdashboard/api/v1/AgencyWorkOrder/GetOpenWorkOrders',
    GET_PASSWORD_POLICY: '/agentdashboard/api/v1/okta/get-password-policy',
    GET_OKTA_AGENT_LIST: '/agentdashboard/api/v1/okta/ListOktaUsersByAgentNumber',
    GET_OKTA_AGENTS_LIST_By_NAMEOrEMAIL: '/agentdashboard/api/v1/okta/ListOktaUsersByAgentNameOrEmail',
    GET_AGENT_INSURANCE_COMPANIES: '/agentdashboard/api/v1/AgencyWorkOrder/GetAgentInsuranceCompanyNamesById',
    GET_CE_COURSE_LIST:'/agentdashboard/api/v1/CECourses/GetCECourseList',
    GET_MY_CE_COURSE_LIST:'/agentdashboard/api/v1/CECourses/GetMyCECourseList',
    GET_IS_REGISTERED_USER: '/agentdashboard/api/v1/CECourses/GetIsRegisteredUser',
    GET_CE_COURSE_DETAILS_BY_COURSEID: '/agentdashboard/api/v1/CECourses/GetCECourseDetailByCourseID',
    GET_CE_COURSE_IMAGES:'/agentdashboard/api/v1/CECourses/GetCECourseImages',
    DELETE_CE_COURSE: '/agentdashboard/api/v1/CECourses/DeleteCECourse',
    SAVE_COURSE: '/agentdashboard/api/v1/CECourses/SaveCourse',
    GET_AGENCY_INSURANCE_COMPANIES: '/agentdashboard/api/v1/AgencyWorkOrder/GetAgencyInsuranceCompanyNamesById',
    GET_ALL_INSURANCE_COMPANIES: '/agentdashboard/api/v1/User/GetInsuranceCompanies',
    SETUP_LAYOUT: '/agentdashboard/api/v1/setup',
    GET_CREATE_ACTIVE_USER: '/agentdashboard/api/v1/okta/CreateActiveUser',
    UPDATE_AGENT_INFORMATION: '/agentdashboard/api/v1/okta/UpdateUserAccountDetails',
    UPDATE_AGENT_CONTACT_PREFS: '/agentdashboard/api/v1/User/UpdateUserAgentContactPrefs',
    GET_REFERRAL_SUMMARY: '/agentdashboard/api/v1/AgencyWorkOrder/ServiceActivityDetail',
    GET_USER_DETAILS: '/agentdashboard/api/v1/User/GetUserDetails?userId=',
    GET_USER_PROFILE: '/agentdashboard/api/v1/User/GetUserProfile/',
    GET_USER_AGENCY_INFORMATION: '/agentdashboard/api/v1/User/GetUserAgentInformation',
    UPDATE_USER_AGENT_CARRIERS: '/agentdashboard/api/v1/User/UpdateUserAgentCarriers',
    REMOVE_XYZAccounts: '/agentdashboard/api/v1/User/RemoveXYZAccountsByUserId',
    CHANGE_USER_PASSWORD: '/agentdashboard/api/v1/User/ChangeUserPassword',
    RESETMFA: '/agentdashboard/api/v1/InternalAdmin/ResetMFA',
    DOWNLOAD_RECALIBRATION_CERTIFICATE: '/agentdashboard/api/v1/AgencyWorkOrder/DownloadRecalibrationCertificate',
    GET_SIGN_IN_DETAILS: '/agentdashboard/api/v1/User/GetUserDetails',
    GET_ZIPCODE_SERVICE_AVAILIBILITY: '/agentdashboard/api/v1/QuickQuote/PolicyHolderLocation/ValidateProviderService',
    DEACTIVATE_USER: '/agentdashboard/api/v1/okta/DeactivateUser',
    SEND_REQUEST_ADMIN_ACCESS_EMAIL: '/agentdashboard/api/v1/User/SendRequestAdminAccessEmail',
    GET_ACCOUNT_MANAGER_DETAILS: '/agentdashboard/api/v1/AccountManager/GetAccountManagerDetails',
    GET_VEHICLE_YEARS: "/agentdashboard/api/v1/QuickQuote/VehicleSelection/GetVehicleYears",
    GET_VEHICLE_MAKES: "/agentdashboard/api/v1/QuickQuote/VehicleSelection/GetVehicleMakes",
    GET_VEHICLE_MODELS: "/agentdashboard/api/v1/QuickQuote/VehicleSelection/GetVehicleModels",
    GET_VEHICLE_STYLES: "/agentdashboard/api/v1/QuickQuote/VehicleSelection/GetVehicleStyles",
    GET_VEHICLE_BY_VIN: "/agentdashboard/api/v1/QuickQuote/VehicleSelection/GetVehicleInfoByVin",
    GET_VEHICLE_BY_YMMS: "/agentdashboard/api/v1/QuickQuote/VehicleSelection/GetVehicleInformationByYMMS",
    GET_GLASS_OPTIONS_BY_CAR_ID: "/agentdashboard/api/v1/QuickQuote/VehicleDamage/GetGlassOptionsByCarId",
    GET_VEHICLE_PROBLEM_QUESTIONS: "/agentdashboard/api/v1/QuickQuote/VehicleProblemQuestions/GetProblemQuestions",
    GET_VEHICLE_PROBLEM_PROPERTY_QUESTIONS:"/agentdashboard/api/v1/QuickQuote/VehicleProblemPropertyQuestions/GetProblemPropertyQuestions",
    GET_QUOTE: "/agentdashboard/api/v1/QuickQuote/Quote/GetQuote",
    GET_COBRANDED_INFO: '/agentdashboard/api/v1/Cobranded/GetIsCobranded',
    GET_IS_ACCOUNT_COBRANDED: "/agentdashboard/api/v1/Cobranded/GetIsAccountCobranded",
    ADD_USER_RESET_PWD_LINK: '/agentdashboard/api/v1/User/AddUserResetPwdLink',
    GET_USER_RESET_PWD_LINK: '/agentdashboard/api/v1/User/GetUserResetPwdLink',
    CREATE_USER_PASSWORD: '/agentdashboard/api/v1/okta/User/CreateUserPassword',
    GET_USER_DETAILS_EMAIL: '/agentdashboard/api/v1/User/GetUserDetailsByEmail?Email=',
    GET_AGENCIES_BY_AGENT: '/agentdashboard/api/v1/AgencySearch/GetAgencyInformationByNumber',
    GET_USER_STATUS: '/agentdashboard/api/v1/okta/User/UserStatus/',
    GET_COBRANDED_LOGO: '/agentdashboard/api/v1/Cobranded/GetCobrandedLogo',
    GET_SELF_SERVICE_URL: '/agentdashboard/api/v1/Cobranded/GenerateSelfServiceUrl',
    CREATE_REFERRAL: '/agentdashboard/api/v1/QuickSubmit/CreateReferral',
    GET_ORDER_SURVEY_DETAILS: '/agentdashboard/api/v1/AgencyWorkOrder/GetSurveyDetails',
    GET_ACCOUNT_NUMBER_FOR_COBRANDED: '/agentdashboard/api/v1/Cobranded/GetCobrandedAccountNumberByClientTag',
    GET_OKTA_AGENT_LIST_VIA_PAGINATION_LINK: '/agentdashboard/api/v1/okta/ListOktaUsersByPaginationLink',
    VERIFY_AGENT_AGENCY_LINK: '/agentdashboard/api/v1/User/VerifyUserAgencyInformation',
    GET_USER_AGENCY_LIST: '/agentdashboard/api/v1/User/GetUserAgencyList',
    SETUP_ANALYTICS: '/agentdashboard/api/v1/setup/SetupAnalytics',
    LOGPAGE_VIEW: '/agentdashboard/api/v1/Analytics/LogPageView',
    GET_ADMIN_REQUESTS: '/agentdashboard/api/v1/InternalAdmin/GetAdminRequests',
    UPDATE_APPROVED_REQUESTS: '/agentdashboard/api/v1/InternalAdmin/ApproveRequest',
    UPDATE_DENIED_REQUESTS: '/agentdashboard/api/v1/InternalAdmin/DenyRequest',
    GET_DASHBOARD_HEALTHCHECK: "/agentdashboard/api/v1/healthcheck",
    GET_DASHBOARD_HEALTHCHECKRESULT: "/agentdashboard/api/v1/healthcheck/HealthCheckResults",
    UPDATE_HEALTHCHECK_CONFIGURATION: "/agentdashboard/api/v1/healthcheck/UpdateConfiguration",
    GET_TIME_ZONES: "/agentdashboard/api/v1/States/GetTimeZones",
    ADD_PARTICIPANTLINK: "/agentdashboard/api/v1/CECourses/AddParticipantLinkDetails",
    GET_CITY: '/agentdashboard/api/v1/QuickQuote/PolicyHolderLocation/GetCityAddress',
    GET_VALIDATE_COURSE_IS_MAXIMUM_LIMIT:'/agentdashboard/api/v1/CECourses/GetValidateCourseIsMaximumLimit',
    GET_USER_AGENCY_CARRIERS: '/agentdashboard/api/v1/User/GetUserAgencyCarriersByAgentNumber',
    UPDATE_USER_DEFAULT_AGENCY: '/agentdashboard/api/v1/User/UpdateUserDefaultAgency',
    REMOVE_USER_FROM_AGENCY: '/agentdashboard/api/v1/User/RemoveUserFromAgency',
    ADD_AGENCY_TO_USER: '/agentdashboard/api/v1/User/SaveUserAgentInfo',
    CLEAR_NOTIFICATION_FLAG: '/agentdashboard/api/v1/User/ClearAgencyNotificationFlag',
    UPDATE_OKTA_AGENCY: '/agentdashboard/api/v1/okta/UpdateOktaAgency',
    GET_CARRIER_SETTINGS: '/agentdashboard/api/v1/Carrier/GetCarrierSettings',
    NOTIFY_USER_LOGGED_IN: '/agentdashboard/api/v1/User/NotifyUserLoggedIn/',
    RUN_EXPERIMENTS_FOR_TRIGGER: '/agentdashboard/api/v1/experiments/RunExperimentsForTrigger',
    GET_BANNER: '/agentdashboard/api/v1/globalbanner/GetBanner',
    GET_CURRENT_BANNER: '/agentdashboard/api/v1/globalbanner/GetCurrentBanner',
    ADD_BANNER: '/agentdashboard/api/v1/globalbanner/CreateBanner',
    EDIT_BANNER: '/agentdashboard/api/v1/globalbanner/EditBanner',
    DELETE_BANNER: '/agentdashboard/api/v1/globalbanner/DeleteBanner',
    GET_SHARED_HEALTHCHECK: "/agentdashboard/api/v1/healthcheck",    
    GET_SHARED_HEALTHCHECKRESULT: "/agentdashboard/api/v1/healthcheck/HealthCheckResults"
}

const contentSiteApiConsts = {
    PAGE_CONTENTS_ENDPOINT: '/api/v1/contentmanagement/contents/',
    GET_ARTICLE_LIST_ENDPOINT: '/api/v1/contentmanagement/ArticleList/',
    GET_TAG_LIST_ENDPOINT: '/api/v1/contentmanagement/TagList',
    GET_SEARCH_RESULTS: '/api/v1/contentmanagement/search/',
    GET_CONTENT_MANGEMENT_HEALTHCHECK: "/api/v1/healthcheck",
    GET_CONTENT_MANGEMENT_HEALTHCHECKRESULT: "/api/v1/healthcheck/HealthCheckResults"
}

const globalValueConsts = {
    NEXT_PAGE_SET_BUTTON: 0,
    PREVIOUS_PAGE_SET_BUTTON: -1,
    MOBILE_BREAKPOINT: 767,
    MOVE_TO_TOP_LOCATION: "move-top-here",
    ALERT_BANNER_RED: "warning",
    ALERT_BANNER_GREEN: "success",
    ALERT_BANNER_GREEN_PERSIST: "success-persist",
    TEXT_PHONE_NUMBER: process.env.VUE_APP_TEXT_PHONE_NUMBER
}

const cookieKeys = {
    LOGGED_OUT_USER_ID: "logged_out_user_id"
}

const queryStringConsts = {
    REFERRAL: "referral"
}

const glassTypeConsts = {
    ALL: "All",
    WINDSHIELD: "Windshield",
    PASSENGER: "Passenger's side",
    DRIVER: "Driver's side",
    BACK: "Back glass",
    BACK_GLASS: "BackGlass",
    SLIDER: "Slider",
    STATIONARY: "Stationary",
    SINGLE_WINDSHIELD: "Single windshield",
    DRIVER_SIDE_SPLIT: "Driver's side split",
    PASSENGER_SIDE_SPLIT: "Passenger's side split",
    DRIVER_FRONT_DOOR_GLASS: "DriverFrontDoorGlass",
    DRIVER_REAR_DOOR_GLASS: "DriverRearDoorGlass",
    DRIVER_REAR_QUARTER_GLASS: "DriverRearQuarterGlass",
    DRIVER_SIDE_DOOR_GLASS: "DriverSideDoorGlass",
    DRIVER_VENT_GLASS: "DriverVentGlass",
    PASSENGER_FRONT_DOOR_GLASS: "PassengerFrontDoorGlass",
    PASSENGER_REAR_DOOR_GLASS: "PassengerRearDoorGlass",
    PASSENGER_REAR_QUARTER_GLASS: "PassengerRearQuarterGlass",
    PASSENGER_SIDE_DOOR_GLASS: "PassengerSideDoorGlass",
    PASSENGER_VENT_GLASS: "PassengerVentGlass",
    SUNROOF: "Sunroof",
    VENT_GLASS: "VentGlass"
}

const glassColorConst = {
    BLUE_TINT_BLUE_SHADE: "Blue-Tint, Blue Shade",
    BLUE_TINT_GREEN_SHADE: "Blue Tint, Green Shade",
    BLUE_TINT_PRIVACY: "Blue Tint Privacy",
    BLUE_TINT: "Blue Tint",
    BLUE_TINT_GRAY_SHADE: "Blue Tint, Gray Shade",
    CLEAR_BLUE_SHADE: "Clear Blue Shade",
    CLEAR_BLACK_FRAME: "Clear Black Frame",
    CLEAR: "Clear",
    GREEN_TINT_BLUE_SHADE: "Green Tint, Blue Shade",
    GREEN_TINT_GREEN_SHADE: "Green Tint, Green Shade",
    GREEN_TINT_PRIVACY: "Green Tint Privacy",
    GREEN_TINT: "Green Tint",
    GREEN_TINT_GRAY_SHADE: "Green Tint, Gray Shade",
    BLACK_PRIVACY: "Black Privacy",
    NOT_AVAILABLE: "Not Available",
    PRIVACY_BLACK_FRAME: "Privacy Black Frame",
    TINTED: "Tinted",
    GRAY_TINT_BLUE_SHADE: "Gray Tint, Blue Shade",
    GRAY_TINT_BLACK_FRAME: "Gray Tint, Black Frame",
    GRAY_TINT_PRIVACY: "Gray Tint Privacy",
    GRAY_TINT: "Gray Tint",
    GRAY_TINT_GRAY_SHADE: "Gray Tint, Gray Shade",
    BRONZE_TINT_BLUE_SHADE: "Bronze Tint, Blue Shade",
    BRONZE_TINT_GREEN_SHADE: "Bronze Tint, Green Shade",
    BRONZE_TINT_PRIVACY: "Bronze Tint Privacy",
    BRONZE_TINT: "Bronze Tint",
    BRONZE_TINT_GRAY_SHADE: "Bronze Tint, Gray Shade",
    BRONZE_TINT_BRONZE_SHADE: "Bronze Tint, Bronze Shade",
    UNKNOWN_COLOR_CODE: "Unknown Color Code",

}

const quoteAccordionConsts = {
    POLICYHOLDER_LOCATION: "Policyholder location",
    VEHICLE_SELECTION: "Vehicle selection",
    VEHICLE_DAMAGE: "Vehicle damage",
    PROBLEM_QUESTIONS: "Problem questions",
    VEHICLE_PARTS_SELECTION: "Vehicle parts selection",
    PROBLEM_PROPERTY_QUESTIONS: "Problem property question",
    SERVICE_QUOTE: "Service quote",
    POLICYHOLDER_LOCATION_REF: "policyholderLocation",
    VEHICLE_SELECTION_REF: "vehicleSelection",
    VEHICLE_DAMAGE_REF: "vehicleDamage",
    PROBLEM_QUESTIONS_REF: "problemQuestions",
    VEHICLE_PARTS_SELECTION_REF: "vehiclePartsSelection",
    PROBLEM_PROPERTY_QUESTIONS_REF: "problemPropertyQuestions",
    SERVICE_QUOTE_REF: "serviceQuote",
}

const OIDC = {
    ISSUER: process.env.VUE_APP_OKTA_ISSUER,
    CLIENT_ID: process.env.VUE_APP_OKTA_CLIENT_ID,
    REDIRECT_URI: process.env.VUE_APP_OKTA_REDIRECT_URI,
    SCOPES: process.env.VUE_APP_OKTA_SCOPES,
    POST_LOGOUT_REDIRECT_URI: process.env.VUE_APP_OKTA_POST_LOGOUT_REDIRECT_URI,
    POST_LOGOUT_REDIRECT_URI_LOGIN: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI_LOGIN,
    ADMIN_USER_ROLE: 'SFAD_Admin',
    AGENT_USER_ROLE: 'SFAD_Agent',
    INTERNAL_ADMIN_ROLE: 'SFAD_InternalAdmin',
    CE_ADMIN_ROLE: 'SFAD_CEAdmin',
}
const dashboardStoreActionConsts = {}


const contentSiteConsts = {
    HOME: `/Home`,
    AGENCY_CARE_DESK: `/agency-care-desk`,
    CONTINUING_EDUCATION_COURSES: '/course-registration',
    SEARCH_RESULTS: '/search',

    ABOUT_SAFELITE: `${baseConsts.SAFELITE_COM_BASE_URL}/about-safelite`,
    CUSTOMER_REVIEWS: `${baseConsts.SAFELITE_COM_BASE_URL}/auto-glass-services/safelite-reviews`,
    SAFELITE_HOME: baseConsts.SAFELITE_COM_BASE_URL,
    MOBILE_AUTO_GLASS_REPAIR: `${baseConsts.SAFELITE_COM_BASE_URL}/mobile-auto-glass-repair`,
    NATIONWIDE_WARRANTY: `${baseConsts.SAFELITE_COM_BASE_URL}/national-lifetime-warranty`,
    OUR_LEADERS: `${baseConsts.SAFELITE_COM_BASE_URL}/about-safelite/our-leaders`,
    PRESS_RELEASES: `${baseConsts.SAFELITE_COM_BASE_URL}/about-safelite/press-releases`,
    RECYCLING: `${baseConsts.SAFELITE_COM_BASE_URL}/why-choose-safelite/glass-recycling`,
    SAFELITE_FOUNDATION: `${baseConsts.SAFELITE_COM_BASE_URL}/about-safelite/safelite-autoglass-foundation`,
    STORE_LOCATOR: `${baseConsts.SAFELITE_COM_BASE_URL}/store-locator`,
    WHY_CHOOSE_SAFELITE: `${baseConsts.SAFELITE_COM_BASE_URL}/why-choose-safelite`,
    TERMS_OF_SERVICE: `${baseConsts.SAFELITE_COM_BASE_URL}/terms-of-service`,
    PRIVACY_POLICY: `${baseConsts.SAFELITE_COM_BASE_URL}/safelite-group-privacy-policy`,
    CANCELLATION_POLICY: `${baseConsts.SAFELITE_COM_BASE_URL}/cancellation-refund-policy`,
    DO_NOT_SELL_MY_INFORMATION: `${baseConsts.SAFELITE_COM_BASE_URL}/ccpa-redirect`,
    AGENT_REG_SUCCESSFUL: `${baseConsts.CONTENT_HUB_BASE_URL}${baseConsts.DASHBOARD_BASE}/${routeNameConstants.AGENT_REGISTRATION_SUCCESSFUL}`
}

const widgetConsts = {
    HERO_WIDGET: 'HeroWidget',
    HOW_CAN_WE_HELP: 'HowCanWeHelpWidget',
    REG_AGENCY_CONTACT: 'RegAgencyContactInfoWidget',
    ACTION_CARD_WIDGET: "ActionCardWidget",
    HEADER_ELEMENT_WIDGET: "HeaderElementWidget",
    ADDITIONAL_RESOURCE_CARD_WIDGET: "AdditionalResourceCardWidget",
    COURSES_DESCRIPTION: "CourseDescriptionWidget",
    COURSES_DETAILS: "CoursesDetailsWidget",
    EVENTINFO_DETAILS: "EventInfoDetailsWidget",
    PAGE_CONFIG: 'PageConfigWidget'
}

const globalEventConsts = {
    OPEN_MODAL: "open-modal",
    CLOSE_MODAL: "close-modal",
    SHOW_WINDSHIELD_WAITING_MODAL: "show-windshield-waiting-modal",
    HIDE_WINDSHIELD_WAITING_MODAL: "hide-windshield-waiting-modal",
    OPEN_CHAT: "open-web-chat",
    UPDATE_PROGRESS_BAR: "update-progress-bar",
    AGENT_SEARCH: "search-agent",
    RESET_PASSWORD_EMAIL:"reset_password_email",
    USER_SEARCH_EXECUTED: "search-user",
    AGENT_NAMEOrEMAIL_SEARCH : "search-agentNameOrEmail",
    SHOW_YOUTUBE_MODAL: "show-youtube-modal",
    SHOW_ALERT_BANNER: "show-alert-banner",
    GLOBAL_BANNER_ALERT_ADDED: "banner-added",
    AGENCY_ADD_SEARCH_SUCCESS: "agencySearchExecuted",
    AGENCY_SEARCH_AGENT_NUM_CHANGED: "agency-changed-for-search",
    CLEAR_AGENT_SEARCH: "clear-agency-search",
    CLEAR_AGENCY_ADD_SEARCH: "clear-agency-add-search",
    SHOW_AGENT_SEARCH_ERROR: "show-agent-search-error",
    SHOW_AGENT_SEARCH_ERROR: "show-agent-search-error",
    SHOW_ADD_NEW_USER_BTN: "show-add-new-user-btn",
    SHOW_DUPLICATE_AGENCY_ERROR: "show-duplicate-agency-error",
    CLOSE_ADD_FLOW: "close-add-flow",
    CHECK_AGENCY_ADD_ERRORS: "check-agency-add-errors",
    VIN_SEARCHED: "vin-searched",
    CONTINUE_CLICKED: "continue-clicked"
}

const navigationScenarioConstants = {
    NAVIGATE_TO_DASHBOARD: "NavigateToDashboard",
    NAVIGATE_TO_AGENCY_LIST: "NavigateToAgencyList",
    NAVIGATE_TO_AGENCY_NOT_FOUND: "NavigateToAgencyNotFound",
    NAVIGATE_TO_FORGOT_PASSWORD: "NavigateToForgotPassword",
    NAVIGATE_TO_HELP: "NavigateToHelp",
    NAVIGATE_TO_FIND_MY_AGENCY: "NavigateToFindMyAgency",
    NAVIGATE_TO_AGENT_DASHBOARD: 'NavigateToAgentDashboard',
    NAVIGATE_TO_LOGIN_SCREEN: 'NavigateToLogin',
    NAVIGATE_TO_AGENT_REGISTRATION: 'NavigateToAgentRegistration',
    NAVIGATE_TO_FILE_A_CLAIM: 'NavigateToFileAClaim',
    NAVIGATE_TO_FILE_CLAIM: 'NavigateToFileClaim',
    NAVIGATE_TO_CREATE_CE_COURSES: 'NavigateToCreateCECourses',
    NAVIGATE_TO_START_CLAIM: 'NavigateToStartClaim',
    NAVIGATE_TO_MANAGE_CLAIM: 'NavigateToManageClaim',
    NAVIGATE_TO_MANAGE_CECOURSES: 'NavigateToManageCECourses', 
    NAVIGATE_TO_WORK_ORDER_DETAILS: 'NavigateToWorkOrderDetails',
    NAVIGATE_TO_USER_DETAILS: 'NavigateToUserDetails',
    NAVIGATE_TO_AGENT_ENROLLMENT: 'NavigateToAgentEnrollment',
    NAVIGATE_TO_MANAGE_USERS: 'NavigateToManageUsers',
    NAVIGATE_TO_REGISTRATION_SUCCESSFUL: 'NavigateToRegistrationSuccessful',
    NAVIGATE_TO_POLICY_HOLDER_QUOTE: 'NavigateToPolicyHolderQuote',
    NAVIGATE_TO_INTERNAL_ADMIN_DASHBOARD: 'NavigateToInternalAdminDashboard',
    NAVIGATE_TO_APPOINTMENT_OPTION_DETAILS: 'NavigateToappointmentoptiondetails',
    NAVIGATE_TO_SUBMIT_CLAIM_REVIEW_DETAILS: 'NavigateToSubmitClaimReviewdetails',
    NAVIGATE_TO_SUCCESS_SUBMIT_CLAIM: 'NavigateToSuccessSubmitClaim',
    NAVIGATE_TO_CONTENT_HUB_HOME: 'NavigateToContentHubHome',
    NAVIGATE_TO_CONTENT_ARTICLE_DETAILS: 'NavigateToArticleDetails',
    NAVIGATE_TO_AGENCY_CARE_DESK: 'NavigateToAgencyCareDesk',
    NAVIGATE_TO_CE_COURSES_HOME: 'NavigateToCECoursesHome',
    NAVIGATE_TO_ORDER_SURVEY_DETAILS: 'NavigateToOrderSurveyDetails',
    NAVIGATE_TO_PRODUCTION_MONITORING: 'NavigateToProductionMonitoring',
    NAVIGATE_TO_CE_COURSE_REGISTRATION: 'NavigateToCERegistrationPage',
    NAVIGATE_TO_CE_COURSE_REGISTRATION_UNAUTH: 'NavigateToCERegistrationPageUnAuth',
    NAVIGATE_TO_UPCOMING_COURSES: 'NavigateToUpcomingCourses',
    NAVIGATE_TO_MY_COURSES: 'NavigateToMyCourses',
    NAVIGATE_TO_MY_COMPLETED_COURSES: 'NavigateToMyCompletedCourses',
    NAVIGATE_TO_LINKEXPIRED_SCREEN: 'NavigateToLinkExpired'
};

const commonMiscConstants = {
    FOCUS_SELECTOR: "a[href], input, select, textarea, button, [tabindex]:not([tabindex='-1']",
    VEHICLE_PREVENT_SAFELITE_SCHEDULE: "ExcludeSafeliteShops",
};

const salesforceContinuingEducationEnvironmentVariables = {
    SALESFORCE_COURSE_REG_URL: process.env.VUE_APP_SALESFORCE_COURSE_REG_URL_VALUE,
    SALESFORCE_ENV_ORG_ID: process.env.VUE_APP_SALESFORCE_COURSE_REG_ENV_ORG_ID_VALUE,
    SALESFORCE_RECORD_TYPE: process.env.VUE_APP_SALESFORCE_COURSE_REG_RECORD_TYPE_VALUE,
    SALESFORCE_COURSE_ID_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_COURSE_ID_KEY,
    SALESFORCE_FIRST_NAME_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_FIRST_NAME_KEY,
    SALESFORCE_LAST_NAME_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_LAST_NAME_KEY,
    SALESFORCE_TITLE_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_TITLE_KEY,
    SALESFORCE_AGENCY_NAME_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_AGENCY_NAME_KEY,
    SALESFORCE_EMAIL_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_EMAIL_KEY,
    SALESFORCE_PHONE_NUMBER_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_PHONE_NUMBER_KEY,
    SALESFORCE_NPNID_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_NPNID_KEY,
    SALESFORCE_ADDRESS_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_ADDRESS_KEY,
    SALESFORCE_CITY_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_CITY_KEY,
    SALESFORCE_STATE_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_STATE_KEY,
    SALESFORCE_ZIP_KEY: process.env.VUE_APP_SALESFORCE_COURSE_REG_ZIP_KEY
}

const salesforceChatEnvironmentVariables = {
    SALESFORCE_URL: process.env.VUE_APP_SALESFORCE_URL,
    SALESFORCE_CHAT_ENDPOINT: process.env.VUE_APP_SALESFORCE_CHAT_ENDPOINT, 
    SALESFORCE_CHAT_ID: process.env.VUE_APP_SALESFORCE_CHAT_ID,
    SALESFORCE_BASE_LIVE_AGENT_CONTENT_URL: process.env.VUE_APP_SALESFORCE_BASE_LIVE_AGENT_CONTENT_URL,
    SALESFORCE_DEPLOY_ID: process.env.VUE_APP_SALESFORCE_DEPLOY_ID,
    SALESFORCE_BUTTON_ID: process.env.VUE_APP_SALESFORCE_BUTTON_ID,
    SALESFORCE_BASE_LIVE_AGENT_URL: process.env.VUE_APP_SALESFORCE_BASE_LIVE_AGENT_URL,
    SALESFORCE_ESW_LIVE_AGENT_NAME: process.env.VUE_APP_SALESFORCE_ESW_LIVE_AGENT_NAME,
    SALESFORCE_EMBEDDED_SOURCE: process.env.VUE_APP_SALESFORCE_EMBEDDED_SOURCE
}

const experimentConsts = {
    PAGE_ENTRY: "PageEntry",
    SITE_ENTRY: "SiteEntry"
}

const registrationLimitsConsts = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150]


const customSingleSelectConsts = {
    AGENCY_DROPDOWN_LABEL: 'agencyNameAndAddress',
    AGENCY_DROPDOWN_TRACKBY: 'agencyNumber'
}

export {
    OIDC,
    apiPrefixConsts,
    dashboardStoreActionConsts,
    experimentConsts,
    dashboardApiEndpointConsts,
    contentSiteApiConsts,
    contentSiteConsts,
    navigationScenarioConstants,
    baseConsts,
    globalEventConsts,
    workOrderStatusData,
    workOrderSearchProp,
    adminRequestFilterProp,
    globalValueConsts,
    commonMiscConstants,
    errorMsgConstants,
    routeNameConstants,
    glassTypeConsts,
    widgetConsts,
    salesforceContinuingEducationEnvironmentVariables,
    glassColorConst,
    cookieKeys,
    salesforceChatEnvironmentVariables,
    adminRequestStatus,
    pageConfigLayoutStyle,
    registrationLimitsConsts,
    customSingleSelectConsts,
    globalEventMsgConstants,
    quoteAccordionConsts,
    queryStringConsts
};