import { defineStore } from 'pinia'
import httpClient from '@/http-client.js';
import {
  dashboardApiEndpointConsts
} from "@/constants.js";


export const useClaimStore = defineStore('claim', {
    state: () => ({
        claimFlowEditFlag: false,
        additionalDetails: {
          streetAddress: null,
          city: null,
          selectedState: null,
          zipCode: null,
          damageDate: null,
          anyOther: null
      },
      activeAccordionNumber:{
        StepValue:''
      },
        appointmentOptionDetails: {
            PreferredContactMethod: '',
            TextCheckBox:false,
        },
        cashQuoteInformation: {
          ZipCode: null,
          Vin: null,
          Year: null,
          Make: null,
          Model: null,
          Style: null,
          AvailableGlass: [],
          VehicleQuestions: [],
          CarId: null,
          VehicleSelectionMethod: "VIN",
          SelectedGlassGroups: [],
          SelectedGlassTypes: {},
          RepairOrReplace: null,
          NumberOfChipsOrCracks: -1,
          SelectedSplitGlasses: [],
          Category: null,
          SelectedGlass: [],
          ProblemQuestions: [],
          PartNumbers: [],
          ChildPartNumbers: [],
          VehiclePartsSelectionQuestions: [],
          VehiclePartsSelectedQuestions: [],
          VehicleExceptions: [],
          FilteredPartsList: [],
          SelectedGlasses: []
      },
        insuranceClaimAgentInfo: {
            Pref_TxtAlertOnPolicyholderContact: false,
            Pref_EmailAlertOnPolicyholderContact: false,
            Pref_TxtAlertOnServiceSchedule: false,
            Pref_EmailAlertOnServiceSchedule: false,
            Pref_TextAlertOnServiceComplete: false,
            Pref_EmailAlertOnServiceComplete: false,
            Pref_TextAlertOnSurveyComplete: false,
            Pref_EmailAlertOnSurveyComplete: false,
            AgentPhoneNo: '',
        },
        insuranceClaimDetails: {
          policyNumber: null,
          firstName: null,
          lastName: null,
          emailId: null,
          carrier: {},
          deductible: null,
          phoneNumber: null
        },
        orderSubmitInformation: {
            AccountNumber: '',      
            Agent: {
                AgentNumber: '',
                AgentFirstName : '',
                AgentLastName : '',
                CallType:'',
                SchedulingOption: '',
                InsuredProviderPreference:'',
                IsCoBrandedSubmission: false,
                IsFromSafeliteForAgents: true,
                AgentEmailAddress: '',
                AgentContactPhone : '',
                Notification: {
                    ShouldAlertOnServiceComplete: '',
                    ShouldAlertOnServiceScheduled: '',
                    ShouldAlertOnSurveyComplete: '',
                    ShouldAlertOnCustomerContactedForScheduling: '',
                    SendEmail: false,
                    SendSMS: false,
                },
            },
            Customer: {
                FirstName: '',
                LastName: '',
                Email: '',
                PhoneNumber: '',
                PhoneNumberAlt: '',
                OptInSms: false,
            },
            Service: {
                Address: '',
                City: '',
                State: '',
                ZipCode: '',
            },
            Vehicle: {
                Country: "US",
                Year: 0,
                Make: '',
                Model: '',
                BodyStyle: '',
                CarId: '',
            },
            Insurance: {
                PolicyNumber: '',
                Deductible: '',
            },
            User: {
                FirstName:'',
                LastName:'',
            },
        }, //orderSubmitInformation
        workOrder: {},
        workOrderDetailsRequest: {
            referralSequenceNumber: null,
            workOrderId: null,
            type: null,
            jobStatus: null,
        },
    }),
    persist: true,
    getters: {},
    actions: {
        getRecalibrationCertificateAsync(args) {
            return httpClient.callHttpClient({
              method: 'GET',
              endpoint: dashboardApiEndpointConsts.DOWNLOAD_RECALIBRATION_CERTIFICATE + "/" + args.workOrderID,
              payload: {},
              responseType: 'blob'
            })
          },
        execCreateReferralAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.CREATE_REFERRAL,
            payload: args.createOrderRequest,
          })
            .then(function (response) {
              return response;
            });
        },
        getCompletedWorkOrdersAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_COMPLETED_WORK_ORDERS,
            payload: {
              "AgencyNumber": args.agencyNumber,
              "AgentName": args.agentName
            },
          })
            .then(function (response) {
              return response.data;
            });
        },
        getGlassOptionsByCarIdAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_GLASS_OPTIONS_BY_CAR_ID,
            payload: {
              AvailableGlass: args.availableGlass,
              CarId: args.carId,
              ZipCode: args.zipCode
            },
          })
            .then(function (response) {
              return response.data;
            });
        },
        getOpenWorkOrdersAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_OPEN_WORK_ORDERS,
            payload: {
              "AgencyNumber": args.agencyNumber,
              "AgentName": args.agentName
            },
          })
            .then(function (response) {
              return response.data;
            });
        },
        getQuoteAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_QUOTE,
            payload: args.quoteRequest,
          })
            .then(function (response) {
              return response.data;
            });
        },
        getSurveyDetailsAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_ORDER_SURVEY_DETAILS,
            payload: {
              "surveyRequest": [args.surveyRequest]
            },
          })
            .then(function (response) {
              return response.data;
            });
        },
        getVehicleByVINAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_VEHICLE_BY_VIN,
            payload: {
              Vin: args.vinPayload.vin
            },
            ignoreErrors: true
          })
          .then(function (response) {
              return response.data;
          });
        },
        getVehicleByYMMSAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_VEHICLE_BY_YMMS,
            payload: {
              Year: args.year,
              Make: args.make,
              Model: args.model,
              Style: args.style
            },
          })
            .then(function (response) {
              return response.data;
            });
        },
        getVehicleYearsAsync(args) {
          return httpClient.callHttpClient({
            method: 'GET',
            endpoint: dashboardApiEndpointConsts.GET_VEHICLE_YEARS,
            payload: {},
          })
            .then(function (response) {
              return response.data;
            });
        },
        getVehicleMakesAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_VEHICLE_MAKES,
            payload: {
              Year: args.payload.year
            },
          })
            .then(function (response) {
              return response.data;
            });
        },
        getVehicleModelsAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_VEHICLE_MODELS,
            payload: {
              Year: args.payload.year,
              Make: args.payload.make
            },
          })
            .then(function (response) {
              return response.data;
            });
        },
        getVehicleStylesAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_VEHICLE_STYLES,
            payload: {
              Year: args.payload.year,
              Make: args.payload.make,
              Model: args.payload.model
            },
          })
            .then(function (response) {
              return response.data;
            });
        },
        getVehicleProblemPropertyQuestionsAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_VEHICLE_PROBLEM_PROPERTY_QUESTIONS,
            payload: {
              CarId: args.carId,
              PartNumber: args.partNumber
            },
          })
            .then(function (response) {
              return response.data;
            });
        }, 
        getVehicleProblemQuestionsAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_VEHICLE_PROBLEM_QUESTIONS,
            payload: {
              SelectedGlass: args.selectedGlass,
              CarId: args.carId,
              ZipCode: args.zipCode,
              Vin: args.vin
            },
          })
            .then(function (response) {
              return response.data;
            });
        },
        getReferralSummaryAsync(args) {
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.GET_REFERRAL_SUMMARY,
            payload: {
              referralNumber: args.referralNumber,
              workOrderId: args.workOrderId,
            },
          })
            .then(function (response) {
              return response;
            });
        },
        clearCashQuoteInformation(args) {
          this.cashQuoteInformation = {
            ZipCode: null,
            Vin: null,
            Year: args.ClearYMMSDetails || !this.cashQuoteInformation || !this.cashQuoteInformation.Year ? null : this.cashQuoteInformation.Year,
            Make: args.ClearYMMSDetails || !this.cashQuoteInformation || !this.cashQuoteInformation.Make ? null : this.cashQuoteInformation.Make,
            Model: args.ClearYMMSDetails || !this.cashQuoteInformation || !this.cashQuoteInformation.Model ? null : this.cashQuoteInformation.Model,
            Style: args.ClearYMMSDetails || !this.cashQuoteInformation || !this.cashQuoteInformation.Style ? null : this.cashQuoteInformation.Style,
            AvailableGlass: [],
            VehicleQuestions: [],
            CarId: null,
            VehicleSelectionMethod: "VIN",
            SelectedGlassGroups: [],
            SelectedGlassTypes: {},
            RepairOrReplace: null,
            NumberOfChipsOrCracks: -1,
            SelectedSplitGlasses: [],
            Category: null,
            SelectedGlass: [],
            ProblemQuestions: [],
            PartNumbers: [],
            ChildPartNumbers: [],
            VehiclePartsSelectionQuestions: [],
            VehiclePartsSelectedQuestions: [],
            VehicleExceptions: [],
            FilteredPartsList: [],
            SelectedGlasses: []
          };
        },
        clearAdditionalDetails(args) {
          this.additionalDetails = {
            streetAddress: null,
            city: null,
            selectedState: null,
            zipCode: null,
            damageDate: null,
            anyOther: null
        };
        },
        clearInsuranceClaimDetails(args) {
          this.insuranceClaimDetails = {policyNumber: null,
            firstName: null,
            lastName: null,
            emailId: null,
            carrier: {},
            deductible: null,
            phoneNumber: null};

          this.claimFlowEditFlag = false;
        },
        clearactiveAccordionNumber(args) {
          this.activeAccordionNumber = {
            StepValue: ''
          };
      },

        clearOrderSubmitInformation(args) {
          this.orderSubmitInformation = {
            AccountNumber: '',      
            Agent: {
              AgentNumber: '',
              AgentFirstName : '',
              AgentLastName : '',
              CallType:'',
              SchedulingOption: '',
              InsuredProviderPreference:'',
              IsCoBrandedSubmission: false,
              IsFromSafeliteForAgents: true,
              AgentEmailAddress: '',
              AgentContactPhone : '',
              Notification: {
                ShouldAlertOnServiceComplete: '',
                ShouldAlertOnServiceScheduled: '',
                ShouldAlertOnSurveyComplete: '',
                ShouldAlertOnCustomerContactedForScheduling: '',
                SendEmail: false,
                SendSMS: false,
              },
            },
            Customer: {
              FirstName: '',
              LastName: '',
              Email: '',
              PhoneNumber: '',
              PhoneNumberAlt: '',
              OptInSms: false,
            },
            Service: {
              Address: '',
              City: '',
              State: '',
              ZipCode: '',
            },
            Vehicle: {
              Country: "US",
              Year: 0,
              Make: '',
              Model: '',
              BodyStyle: '',
              CarId: '',
            },
            Insurance: {
              PolicyNumber: '',
              Deductible: '',
            },
            User: {
              FirstName:'',
              LastName:'',
            },
          
          }
        },
        clearWorkOrder(args) {
          this.workOrder = {}
        },
        clearAppointmentOptionDetails(args) {
            this.appointmentOptionDetails = {
                PreferredContactMethod: '',
                TextCheckBox:false,
            }
        },
        clearWorkOrderDetailsRequest(args) {
          this.workOrderDetailsRequest = {}
        }      
    },
})
