////////////////////////////////////////////////////////////////////////////////////
// Our main router file, imports our Dashboard / (local) Content Site Routes.    //
// Also handles our 'not found' route                                           //
//////////////////////////////////////////////////////////////////////////////////

import { dashboardRoutes } from "@/router/routes.js";
import { createWebHistory, createRouter } from "vue-router";
import { OIDC,
         navigationScenarioConstants, 
         routeNameConstants, 
         globalValueConsts 
        } from "@/constants"
import { StatusCodes } from 'http-status-codes';
import globalMethods from "@/global-methods.js";
import { useUserStore } from "@/stores/user.js"
import { useSfaStore } from "@/stores/sfa.js"
import { useClaimStore } from "@/stores/claim.js"
import { useAgencyCarrierStore } from "@/stores/agencycarrier.js"
import { contentSiteRoutes } from "./contentSiteRoutes.js";

var userStore = null;
var sfaStore = null;
var claimStore = null;
var agencyCarrierStore = null;

let routes = [
    {
        path: '/',
        name: 'root',
        redirect: '/'
    }
];

let fileClaimRoutes= [
    routeNameConstants.START_CLAIM.toLowerCase(),
    routeNameConstants.MANAGE_CLAIM.toLowerCase(),
    routeNameConstants.APPOINTMENT_OPTION_DETAILS.toLowerCase(),
    routeNameConstants.SUBMIT_CLAIM_REVIEW_DETAILS.toLowerCase(),
    routeNameConstants.SUCCESS_SUBMIT_CLAIM.toLowerCase(),
]

//Concat our routes
routes = dashboardRoutes.concat(contentSiteRoutes, routes);

const WILD_CARD = "*";

const router = createRouter({
    history: createWebHistory("/"),
    routes,
    //Cause "page" to begin at the top when route chanages.
    scrollBehavior(to, from, savedPosition) {
            if (!(from.name === "Tagged Articles" && to.name === "Tagged Articles" && window.innerWidth <= globalValueConsts.MOBILE_BREAKPOINT)) {
                return { x: 0, y: 0 }
            }
        },
    });

///////////////////
// Route mapping //
///////////////////

const routingTable = [
    //Page-specific routes

    {
        sourceRouteName: WILD_CARD,
        maps: [{
            scenario: navigationScenarioConstants.NAVIGATE_TO_AGENCY_NOT_FOUND,
            destinationRouteName: routeNameConstants.AGENCY_NOT_FOUND_PAGE
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_AGENCY_LIST,
            destinationRouteName: routeNameConstants.SELECT_AGENCY_PAGE
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_FIND_MY_AGENCY,
            destinationRouteName: routeNameConstants.FIND_AGENCY_PAGE
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_AGENT_DASHBOARD,
            get destinationRouteName() {
                if (userStore.userRole == OIDC.INTERNAL_ADMIN_ROLE) {
                    return routeNameConstants.INTERNAL_ADMIN_DASHBOARD;
                } else {
                    return routeNameConstants.ADMIN_AGENT_DASHBOARD;
                }
            }
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_CE_COURSES_HOME,
            destinationRouteName: routeNameConstants.CE_COURSES_HOME
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_UPCOMING_COURSES,
            destinationRouteName: routeNameConstants.CE_COURSES_UPCOMING
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_MY_COURSES,
            destinationRouteName: routeNameConstants.CE_MYCOURSES
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_MY_COMPLETED_COURSES,
            destinationRouteName: routeNameConstants.CE_COMPLETED_MYCOURSES
        },         
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_DASHBOARD,
            destinationRouteName: routeNameConstants.DASHBOARD
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_LOGIN_SCREEN,
            destinationRouteName: routeNameConstants.LOGIN_SCREEN
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_FORGOT_PASSWORD,
            destinationRouteName: routeNameConstants.FORGOT_PASSWORD
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_AGENT_REGISTRATION,
            destinationRouteName: routeNameConstants.AGENT_REGISTRATION
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_FILE_CLAIM,
            get destinationRouteName() {
                if (userStore.userRole == OIDC.CE_ADMIN_ROLE || userStore.userRole == OIDC.INTERNAL_ADMIN_ROLE) {
                    return routeNameConstants.INTERNAL_ADMIN_DASHBOARD
                } else {
                    return routeNameConstants.FILE_CLAIM
                }
                
            } 
        },
        // {
        //     scenario: navigationScenarioConstants.NAVIGATE_TO_USER_DETAILS,
        //     get destinationRouteName() {
        //         if (userStore.userRole == OIDC.CE_ADMIN_ROLE || userStore.userRole == OIDC.INTERNAL_ADMIN_ROLE) {
        //             return routeNameConstants.INTERNAL_ADMIN_DASHBOARD
        //         } else {
        //             return routeNameConstants.USER_DETAILS
        //         }
        //     } 
        // },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_CREATE_CE_COURSES,
            destinationRouteName: routeNameConstants.CREATE_CE_COURSES
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_START_CLAIM,
            get destinationRouteName() {
                if (userStore.userRole == OIDC.CE_ADMIN_ROLE) {
                    return routeNameConstants.NOT_FOUND_PAGE
                } else {
                    return routeNameConstants.START_CLAIM
                }
                
            } 
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_MANAGE_CLAIM,
            get destinationRouteName() {
                if (userStore.userRole == OIDC.CE_ADMIN_ROLE) {
                    return routeNameConstants.NOT_FOUND_PAGE
                } else {
                    return routeNameConstants.MANAGE_CLAIM
                }
                
            } 
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_MANAGE_CECOURSES,
            destinationRouteName: routeNameConstants.MANAGE_CECOURSES
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_WORK_ORDER_DETAILS,
            destinationRouteName: routeNameConstants.WORDK_ORDER_DETAILS
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_USER_DETAILS,
            destinationRouteName: routeNameConstants.USER_DETAILS
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_AGENT_ENROLLMENT,
            destinationRouteName: routeNameConstants.AGENCY_ADMIN_ADD
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_MANAGE_USERS,
            destinationRouteName: routeNameConstants.MANAGE_USERS
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_REGISTRATION_SUCCESSFUL,
            destinationRouteName: routeNameConstants.AGENT_REGISTRATION_SUCCESSFUL
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_POLICY_HOLDER_QUOTE,
            destinationRouteName: routeNameConstants.POLICY_HOLDER_QUOTE
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_POLICY_HOLDER_QUOTE,
            destinationRouteName: routeNameConstants.POLICY_HOLDER_QUOTE
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_INTERNAL_ADMIN_DASHBOARD,
            destinationRouteName: routeNameConstants.INTERNAL_ADMIN_DASHBOARD
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_APPOINTMENT_OPTION_DETAILS,
            destinationRouteName: routeNameConstants.APPOINTMENT_OPTION_DETAILS
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_SUBMIT_CLAIM_REVIEW_DETAILS,
            destinationRouteName: routeNameConstants.SUBMIT_CLAIM_REVIEW_DETAILS
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_SUCCESS_SUBMIT_CLAIM,
            destinationRouteName: routeNameConstants.SUCCESS_SUBMIT_CLAIM
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_CONTENT_HUB_HOME,
            destinationRouteName: routeNameConstants.CONTENT_SITE_HOME
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_CONTENT_ARTICLE_DETAILS,
            destinationRouteName: routeNameConstants.CONTENT_SITE_ARTICLE_DETAILS
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_AGENCY_CARE_DESK,
            destinationRouteName: routeNameConstants.CONTENT_SITE_AGENCY_CARE_DESK
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_ORDER_SURVEY_DETAILS,
            destinationRouteName: routeNameConstants.ORDER_SURVEY_DETAILS
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_PRODUCTION_MONITORING,
            destinationRouteName: routeNameConstants.PRODUCTION_MONITORING
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_CE_COURSE_REGISTRATION,
            destinationRouteName: routeNameConstants.COURSE_REGISTRATION
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_CE_COURSE_REGISTRATION_UNAUTH,
            destinationRouteName: routeNameConstants.COURSE_REGISTRATION_UNAUTH
        },
        {
            scenario: navigationScenarioConstants.NAVIGATE_TO_LINKEXPIRED_SCREEN,
            destinationRouteName: routeNameConstants.LINKEXPIRED_SCREEN
        },
        ]
    }
];

router.navigate = function (scenario, optionalQuery, optionalParams) {
    if (!scenario) {
        console.log("A SCENARIO WASN'T PROVIDED, NAVIGATING TO ERROR404. PLEASE ADD SCENARIO TO NAVIGATIONMAP IN ROUTER.JS");
        return;
    }

    //Get matchingMap
    const matchingMap = router.getNavigationMap(scenario);

    //Navigate if route
    if (matchingMap.destinationRouteName !== undefined) {
        window.wasRouterPushInvoked = true;
        router.push({
            name: matchingMap.destinationRouteName,
            query: optionalQuery,
            params: optionalParams
        }).catch(error => {
            // Navigation Duplication (Navigating to same link) doesn't need to throw an error.
            if (error.name != "NavigationDuplicated") {
                throw error;
              }
        });
    }
}

//CTNOTE: Do I need to pass currentRoute?
router.getNavigationMap = function (scenario) {
    const currentRouteName = router.resolve(location).matched[0].name;
    const matchedPageMaps = routingTable.filter(x => (x.sourceRouteName === currentRouteName || x.sourceRouteName === WILD_CARD) 
                                                && x.maps.filter(map => map.scenario === scenario || map.scenario === WILD_CARD).length > 0)
                                            .map(x => x.maps.filter(map => map.scenario === scenario || map.scenario === WILD_CARD));
    return matchedPageMaps[0][0];
}

router.beforeEach((to, from, next) => {
    if (!userStore) {
        userStore = useUserStore();
    }
    if (!sfaStore) {
        sfaStore = useSfaStore();
    }
    if (!claimStore) {
        claimStore = useClaimStore();
    }
    if(!agencyCarrierStore) {
        agencyCarrierStore = useAgencyCarrierStore();
    }


    //Reset the logo if needed if we are exiting the file a claim flow.
    var resetActiveLogo = fileClaimRoutes.indexOf(to.name.toLowerCase()) == -1 ? true : false;
    
    if(resetActiveLogo) {
        //Reset the active logo to the default.
        sfaStore.activeLogo = {...sfaStore.defaultLogo};
    }

    //check for the client tag, if it is there, put it in the store. 
    //Should really only be there on initial visit.
    if(Object.hasOwn(to.query, "clientTag")) {
        let clientTag = to.query.clientTag;
        sfaStore.clientTagInfo.tagId = clientTag;

        //Fetch acct number from client tag (if it doesn't have one, it aint cobranded)
        agencyCarrierStore.getAccountNumberFromClientTagAsync({
            blocking: true,
            clientTag: clientTag
        }).then(function (response) {
            if (response != "" && response != null) {
                //Set client tag cookie for use by ISS
                sfaStore.clientTagInfo.details = response;
                sfaStore.clientTagInfo.accountNumber = response.ACCT_NUM;
                
                //Check if client tag account is cobranded
                sfaStore.setLogoByAccount({accountNumber: sfaStore.clientTagInfo.accountNumber, setDefault: true, blocking: true})
                
            } else {
                //Client tag was invalid - kill this now
                sfaStore.clientTagInfo.details = null;
                sfaStore.clientTagInfo.accountNumber = null;
                sfaStore.defaultLogo = {...sfaStore.safeliteLogo};
                sfaStore.activeLogo = {...sfaStore.safeliteLogo};
            }
        });
    }

    
    // No need for setup call on routes that don't need it.
    globalMethods.showWaitingModal(true);

    // Set our title.
    setTitle(to);

    // If we have a 'requireAuth' meta = false, we don't need to call setup to check.
    if (!to.meta?.requiresAuth) {
        globalMethods.showWaitingModal(false);
        next();
    } else {
        sfaStore.execSetupLayoutAsync({
            ToRoute: to.fullPath,
            FromRoute: from.fullPath
        }).then(function (response) {
            // Do success stuff here.
            if (from.name) {
                clearStore(from.name);
            }

            next();
        }).catch(function (error) {
            // Do error stuff here 
            if (error.responseData.status === StatusCodes.UNAUTHORIZED) {
                //If there is a querystring, forward along with the referral qs var
                var queryObject = to.query;
                queryObject.referral = to.name
                next({ name: routeNameConstants.LOGIN_SCREEN, query: queryObject });
            }
        })
            .finally(() => {
                globalMethods.showWaitingModal(false);
            });
    }
})

//CTNOTE: leverage for store cleanup?>
function clearStore(fromName) {
    if (!(fromName == "appointment-option-details" || fromName == "start-claim" || fromName == "submit_claimdetails" || fromName == "manage-claim" || fromName == "submit-claim-review-details")) {
        claimStore.clearCashQuoteInformation({})
        claimStore.clearAppointmentOptionDetails({});
        
    }
}

// Set the title of our tab. If we have meta data use that. If not use the route name.
function setTitle(toRoute) {
    toRoute.meta.title === undefined ? document.title = (toRoute.name === undefined ? 'Safelite For Agents' : toRoute.name) : document.title = toRoute.meta.title
}






export default router
