import { defineStore } from "pinia";
import httpClient from "@/http-client.js";
import { dashboardApiEndpointConsts, contentSiteApiConsts } from "@/constants.js";
import { useAgencyCarrierStore } from "@/stores/agencycarrier.js";
import { baseConsts } from '../constants';

export const useSfaStore = defineStore("sfa", {
    state: () => ({
        experiments: [],
        currentTabAction: "",
        webChatEnabled: false,
        safeliteLogo: {
            src: require("@/assets/logos/branding-logo-safelite-autoglass-desktop-new.svg"),
            alt: "Safelite For Agents"
        },
        activeLogo: {
            src: require("@/assets/logos/branding-logo-safelite-autoglass-desktop-new.svg"),
            alt: "Safelite For Agents"
        },
        defaultLogo: {
            src: require("@/assets/logos/branding-logo-safelite-autoglass-desktop-new.svg"),
            alt: "Safelite For Agents"
        },
        isCobranded: false, //Flag used to determine if the user is receiving the cobranded experience (overall)
        logoLoaded: false,
        clientTagInfo: {
            tagId: "",
            details: {},
            accountNumber: 0
        },
        globalBannerAlert: false,   //flag used to determine if showing global banner.  values are true or false
    }),
    persist: true,
    getters: {
        // isCobranded() {
        //     return cobrandedAccountNumber > 0;
        // }
    },
    actions: {
        setLogoByAccount(args) {
            var self = this;
            var agencyCarrierStore = useAgencyCarrierStore();

            //Check if client tag account is cobranded
            agencyCarrierStore.getIsAccountCobrandedAsync({
                accountNumber: args.accountNumber,
                blocking: true
            }).then(function (isCobrandedResponse) {
                //If we came in through the client portal, they will be given the cobranded experience.
                self.isCobranded = isCobrandedResponse.isCobranded;

                if (self.isCobranded) {
                    //Since client tag lines up with a cobranded account, default logo should be set
                    //to the cobranded logo. Fetch the logo and set it up in the store.
                    var coBrandedLogoAltName = isCobrandedResponse.accountName;
                    var cobrandedAccountNumber = isCobrandedResponse.accountNumber
                    self.getCoBrandedLogosAsync({blocking: true, accountNumber: cobrandedAccountNumber})
                    .then (logoResponse => {
                        //Set up logo from response - read this into memory so page refreshes won't wipe out the image.
                        //readAsDataURL reads image into a base64 string.  this can be used as the image src.
                        if (logoResponse) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                let logo = { src: reader.result, alt: coBrandedLogoAltName };
                                if (args.setDefault) {
                                    self.defaultLogo = {...logo};
                                }
                                self.activeLogo = {...logo};
                            }
                            reader.readAsDataURL(logoResponse);
                    }
                    })
                    .finally(() => {
                        self.logoLoaded = true;
                    });
                } else {
                    if (args.setDefault) {
                        self.defaultLogo = {...self.safeliteLogo};
                    }
                    self.activeLogo = {...self.safeliteLogo};
                }
            })
        },
        getBannerAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "GET",
                    endpoint: dashboardApiEndpointConsts.GET_BANNER,
                    payload: {},
                })
                .then(function (response) {
                    return response;
                });
        },
        getCurrentBannerAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "GET",
                    endpoint: dashboardApiEndpointConsts.GET_CURRENT_BANNER,
                    payload: {},
                })
                .then(function (response) {
                    return response.data;
                });
        },
        execAddBannerAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.ADD_BANNER,
                    payload: {
                        BannerTitle: args.bannerDetails.bannerTitle,
                        BannerAlertText: args.bannerDetails.bannerAlertText,
                        UrlLabel: args.bannerDetails.urlLabel,
                        Url: args.bannerDetails.url,
                        StartDate: args.bannerDetails.startDate,
                        EndDate: args.bannerDetails.endDate,
                        CreatedBy: args.bannerDetails.createdBy,
                    },
                })
                .then(function (response) {
                    return response;
                });
        },
        execEditBannerAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.EDIT_BANNER,
                    payload: {
                        BannerTitle: args.banner.bannerTitle,
                        BannerAlertText: args.banner.bannerAlertText,
                        UrlLabel: args.banner.urlLabel,
                        Url: args.banner.url,
                        StartDate: args.banner.startDate,
                        EndDate: args.banner.endDate,
                        ModifiedBy: args.banner.modifiedBy,
                    },
                })
                .then(function (response) {
                    return response;
                });
        },
        execDeleteBannerAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.DELETE_BANNER,
                    payload: {},
                })
                .then(function (response) {
                    return response;
                });
        },
        getCoBrandedLogosAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.GET_COBRANDED_LOGO,
                    payload: {
                        AccountNumber: args.accountNumber,
                    },
                    responseType: "blob",
                })
                .then(function (response) {
                    return response.data;
                });
        },
        getStatesAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "GET",
                    endpoint: dashboardApiEndpointConsts.GET_STATES,
                    payload: {},
                })
                .then(function (response) {
                    return response.data;
                });
        },
        getTimeZonesAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "GET",
                    endpoint: dashboardApiEndpointConsts.GET_TIME_ZONES,
                    payload: {},
                })
                .then(function (response) {
                    return response;
                });
        },
        execLogPageViewNewAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.LOGPAGE_VIEW,
                    payload: {
                        WebPage: args.WebPage,
                        LogAppDetails: args.LogAppDetails,
                        Event: args.Event,
                        Category: args.Category,
                        Action: args.Action,
                        Label: args.Label,
                        Value: args.Value,
                        ReferralNumber: args.ReferralNumber,
                        UserName: args.UserName,
                    },
                })
                .then(function (response) {
                    return response.data;
                });
        },
        execSetupAndGetAnalyticsDataAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.SETUP_ANALYTICS,
                    payload: {
                        ClientTag: args.ClientTag,
                        UserName: args.UserName,
                        UserAgent: args.UserAgent,
                        DeviceId: args.DeviceId,
                    },
                })
                .then(function (response) {
                    return response.data;
                });
        },
        execSetupLayoutAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.SETUP_LAYOUT,
                    payload: {
                        FromRoute: args.FromRoute,
                        ToRoute: args.ToRoute,
                    },
                })
                .then(function (response) {
                    return response;
                });
        },
        getLocationDataFromZipAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.GET_CITY,
                    payload: {
                        ZipCode: args.ZipCode,
                    },
                })
                .then(function (response) {
                    return response;
                });
        },
        getPasswordPolicyRulesAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "GET",
                    endpoint: dashboardApiEndpointConsts.GET_PASSWORD_POLICY,
                    payload: {},
                })
                .then(function (response) {
                    return response.data;
                });
        },
        getSelfServiceUrlAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.GET_SELF_SERVICE_URL,
                    payload: {
                        UserId: args.userId,
                        AgentNumber: args.agentNumber,
                        ParentAccountNumber: args.parentAccountNumber,
                        ReturnUrl: args.returnUrl,
                        UN: args.userName,
                        FN: args.firstName,
                        LN: args.lastName,
                        PN: args.phoneNumner,
                    },
                })
                .then(function (response) {
                    return response.data;
                });
        },
        //PROD MONITORING ACTIONS
        execUpdateHealthCheckConfigAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.UPDATE_HEALTHCHECK_CONFIGURATION,
                    payload: {
                        configCode: args.config.configCodeName,
                        config: args.config.updadatedEndpoints,
                    },
                })
                .then(function (response) {
                    return response.data;
                });
        },
        getDashboardHealthcheck(args) {
            return httpClient.callHttpClient({
              method: 'GET',
              endpoint: dashboardApiEndpointConsts.GET_DASHBOARD_HEALTHCHECK,
              payload: {},
            })
              .then(function (response) {
                return response.data;
              });
          },
          getDashboardHealthcheckResult(args) {
            return httpClient.callHttpClient({
              method: 'POST',
              endpoint: dashboardApiEndpointConsts.GET_DASHBOARD_HEALTHCHECKRESULT,
              payload: {
                endpoints: args.healthConfig.endpoints
              },
            })
              .then(function (response) {
                return response.data;
              });
          },
      
        getZipCodeServicedStatusAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.GET_ZIPCODE_SERVICE_AVAILIBILITY,
                    payload: {
                        ZipCode: args.zipCode,
                    },
                })
                .then(function (response) {
                    return response;
                });
        },
        // PRODUCTION MONITORING CONTENT MANAGEMENT ACTIONS
        getContentManagementHealthcheckAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "GET",
                    endpoint: contentSiteApiConsts.GET_CONTENT_MANGEMENT_HEALTHCHECK,
                    payload: {},
                })
                .then(function (response) {
                    return response.data;
                });
        },
        getContentManagementHealthCheckResultAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: contentSiteApiConsts.GET_CONTENT_MANGEMENT_HEALTHCHECKRESULT,
                    payload: {
                        config: args.configEndpoints,
                    },
                })
                .then(function (response) {
                    return response.data;
                });
        },
       
        // PRODUCTION MONITORING CONTENT MANAGEMENT ACTIONS ENDS
        ///// END CONTENT SITE ACTIONS /////

        ///// START SHARED ACTIONS /////
        loadExperimentsForTriggerAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.RUN_EXPERIMENTS_FOR_TRIGGER,
                    payload: {
                        ApplicationName: baseConsts.APPLICATION_NAME,
                        UserId: args.userId,
                        TriggerEvent: args.triggerEvent,
                        TriggerValue: args.triggerValue,
                        CurrentExperimentsForUser: args.currentExperiments,
                    },
                })
                .then(function (response) {
                    this.experiments = response.data;

                    return response;
                });
        },

        // PRODUCTION MONITORING ACTIONS
        getSharedServiceHealthcheckAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "GET",
                    endpoint: dashboardApiEndpointConsts.GET_SHARED_HEALTHCHECK,
                    payload: {},
                })
                .then(function (response) {
                    return response.data;
                });
        },

        getSharedServiceHealthcheckResultAsync(args) {
            return httpClient
                .callHttpClient({
                    method: "POST",
                    endpoint: dashboardApiEndpointConsts.GET_SHARED_HEALTHCHECKRESULT,
                    payload: {
                        config: args.sharedEndpoints,
                    },
                })
                .then(function (response) {
                    return response.data;
                });
        },

        logError(message) {
            if (baseConsts.DEBUG_ENABLED) {
                console.log(message);
            }

            httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.VIEW_ERROR,
                payload: { error: message }
            });
        },
        // PRODUCTION MONITORING ACTIONS ENDS
        ///// END SHARED SERVICE ACTIONS /////
    },
});
