import { defineStore } from "pinia";
import httpClient from "@/http-client.js";
import { dashboardApiEndpointConsts } from "@/constants.js";
//import { EventBus } from '@/event-bus';

const accountTPPObject = {
	SFA_TPP_FLAG: false,
};

const agencyInformationObject = {
	agencyName: null,
	addressLineFirst: null,
	addressLineSecond: null,
	city: null,
	state: "Select",
	agencyPhoneNumber: null,
	selectedAgencyAccNo: null,
	profile: {
		username: null,
		password: null,
	},
	AllAgencySearchList: [], //NOTE: this appears to only be used in a unit test file.
};

const agentInformationObject = {
	createUserModel: {
		createUserProfile: {
			profile: {
				firstName: null,
				lastName: null,
				email: null,
				login: null,
			},
			credentials: {
				password: { value: null },
			},
		},
		accountNumberList: null,
		agentNumber: null,
		addressLine1: null,
		addressLine2: null,
		city: null,
		name: null,
		phone: null,
		state: null,
		zip: null,
	},
	AgencyInformation: {
		agentNumber: null,
		agencyName: null,
		agencyAddressLine1: null,
		agencyAddressLine2: null,
		agencyState: null,
		agencyCity: null,
		agencyPhoneNumber: null,
	},
	AgentProfile: {
		profile: {
			firstName: null,
			lastName: null,
			email: null,
			login: null,
			primaryPhone: null,
			mobilePhone: null,
			AGNT_NUM: null,
			npn_id: null,
			title: null,
		},
		credentials: {
			password: {
				hook: {
					type: "default",
				},
			},
		},
	},
	Id: null,
	Role: null,
	UpdateRole: false,
	dashboardRole: null,
	InsuranceCompanyList: null,
	SelectedInsuranceArray: null,
	GroupId: null,
};

const searchAgencyDetailsObject = {
	agentaccountNumber: null,
	agencyName: null,
	agencyPhoneNumber: null,
};

export const useAgencyCarrierStore = defineStore("agencyCarrier", {
	state: () => ({
		//Agent = User, contains information used for both the create
		//user flows and the user profiles
		accountManagerDetails: null,
		accountTPP: { ...accountTPPObject },
		agencyInformation: { ...agencyInformationObject },
		agentInformation: { ...agentInformationObject },
		filteredAgencies: null,
		managedCarrier: false,
		searchAgencyDetails: { ...searchAgencyDetailsObject },
		searchedAgencyText: null,
	}),
	persist: true,
	getters: {},
	actions: {
		clearAgencyInformation() {
			this.agencyInformation = agencyInformationObject;
		},
		clearSearchAgencyDetails() {
			this.searchAgencyDetails = searchAgencyDetailsObject;
		},
		getAccountNumberFromClientTagAsync(args) {
			return httpClient
				.callHttpClient({
					method: "GET",
					endpoint:
						dashboardApiEndpointConsts.GET_ACCOUNT_NUMBER_FOR_COBRANDED +
						"?clientTag=" +
						args.clientTag,
				})
				.then(function (response) {
					return response.data;
				});
		},
		getAgenciesByAgentAsync(args) {
			return httpClient
				.callHttpClient({
					method: "GET",
					endpoint:
						dashboardApiEndpointConsts.GET_AGENCIES_BY_AGENT +
						"?agentNumber=" +
						args.agentNumber,
					payload: {},
				})
				.then(function (response) {
					return response.data;
				});
		},
		getAgencyInsuranceCompaniesAsync(args) {
			return httpClient
				.callHttpClient({
					method: "POST",
					endpoint: dashboardApiEndpointConsts.GET_AGENCY_INSURANCE_COMPANIES,
					payload: {
						AgencyNumber: args.agencyNumber,
						UserId: args.userId,
					},
					bearerToken: args.bearerToken,
				})
				.then(function (response) {
					return response.data;
				});
		},
		getAgentInsuranceCompaniesAsync(args) {
			return httpClient
				.callHttpClient({
					method: "POST",
					endpoint: dashboardApiEndpointConsts.GET_AGENT_INSURANCE_COMPANIES,
					payload: {
						UserId: args.userId,
						AgencyNumber: args.agencyNumber,
					},
				})
				.then(function (response) {
					return response.data;
				});
		},
		getAllAgenciesForSearchAsync(args) {
			var self = this;
			return httpClient
				.callHttpClient({
					method: "GET",
					endpoint:
						dashboardApiEndpointConsts.GET_AGENCY_SEARCH_STRINGS +
						"?agentNumber=" +
						args.agentNumber,
					payload: {},
				})
				.then(function (response) {
					self.searchedAgencyText =
						response.data.AllAgencySearchList.length > 0
							? response.data.AllAgencySearchList[0].Text
							: "";
					return response.data;
				});
		},
		getAndSetAccountManagerDetailsAsync(args) {
			var self = this;
			return httpClient
				.callHttpClient({
					method: "GET",
					endpoint:
						dashboardApiEndpointConsts.GET_ACCOUNT_MANAGER_DETAILS +
						"?AccountNumber=" +
						args.accountNumber,
					payload: {},
					ignoreErrors: true,
				})
				.then(function (response) {
					if (response.data.EMAIL_ADDR != null) {
						self.accountManagerDetails = response.data;
						//EventBus.$emit('AccountDetails', response.data); JF - there is no corresponding $on for this
					}
					return response.data;
				});
		},
		getAndSetFilteredAgenciesAsync(args) {
			var self = this;
			return httpClient
				.callHttpClient({
					method: "POST",
					endpoint: dashboardApiEndpointConsts.GET_AGENCIES,
					payload: {
						AccountNumber: args.accountNumber,
						AgencyName: args.agencyName,
						PrimaryPhone: args.primaryPhone,
						Email: args.email,
					},
				})
				.then(function (response) {
					if (
						response.data.AgencyInformationList != null &&
						response.data.AgencyInformationList.length > 0
					) {
						self.filteredAgencies = response.data.AgencyInformationList;
					}
					return response.data;
				});
		},
		getInsuranceCompaniesAsync() {
			return httpClient
				.callHttpClient({
					method: "POST",
					endpoint: dashboardApiEndpointConsts.GET_ALL_INSURANCE_COMPANIES,
					payload: {},
				})
				.then(function (response) {
					return response.data;
				});
		},
		getIsCobrandedAsync(args) {
			return httpClient
				.callHttpClient({
					method: "POST",
					endpoint: dashboardApiEndpointConsts.GET_COBRANDED_INFO,
					payload: {
						AgencyNumber: args.agencyNumber,
						AccountNumber: args.accountNumber,
						UserId: args.userId,
					},
				})
				.then(function (response) {
					return response.data;
				});
		},
		getIsAccountCobrandedAsync(args) {
			return httpClient
				.callHttpClient({
					method: "POST",
					endpoint: dashboardApiEndpointConsts.GET_IS_ACCOUNT_COBRANDED,
					payload: {
						AccountNumber: args.accountNumber,
					},
				})
				.then(function (response) {
					return response.data;
				});
		},
		getUserAgencyAsync(args) {
			return httpClient
				.callHttpClient({
					method: "POST",
					endpoint: dashboardApiEndpointConsts.GET_USER_AGENCY_INFORMATION,
					payload: { userId: args.userId },
				})
				.then(function (response) {
					return response.data;
				});
		},
		getUserAgencyCarriersAsync(args) {
			return httpClient
				.callHttpClient({
					method: "GET",
					endpoint:
						dashboardApiEndpointConsts.GET_USER_AGENCY_CARRIERS +
						"/" +
						args.userId +
						"/" +
						args.agencyNum,
					payload: {},
				})
				.then(function (response) {
					return response.data;
				});
		},
		getUserAgenciesAsync(args) {
			return httpClient
				.callHttpClient({
					method: "GET",
					endpoint:
						dashboardApiEndpointConsts.GET_USER_AGENCY_LIST +
						"/" +
						args.userName +
						"/" +
						args.oktaAgency,
					payload: {},
				})
				.then(function (response) {
					return response.data;
				});
		},
		removeXYZAccountsAsync(args) {
			return httpClient
				.callHttpClient({
					method: "GET",
					endpoint:
						dashboardApiEndpointConsts.REMOVE_XYZAccounts + "/" + args.userId,
					payload: {},
					bearerToken: args.bearerToken,
				})
				.then(function (response) {
					return response.data;
				});
		},
		setManagedCarrier(arg) {
			if (typeof arg === "string" && (arg === "true" || arg === "false")) {
				this.managedCarrier = Boolean(arg);
			} else if (typeof arg === "boolean") {
				this.managedCarrier = arg;
			}
		},
		getCarrierSettingsAsync(args) {
			return httpClient
				.callHttpClient({
					method: "POST",
					endpoint: dashboardApiEndpointConsts.GET_CARRIER_SETTINGS,
					payload: { acct_Num: args.accountNumber },
				})
				.then(function (response) {
					return response.data;
				});
		},
	},
});
