<template>
  <nav class="general-nav plain-nav">
    <ul class="level-0">
      <li v-for="(link, i) in links" :key="i">
        <a :href="link.destination" :target="link.target">
          {{ link.text }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { contentSiteConsts } from "@/constants.js";

export default {
  name: "site-footer-plain-navigation-section",
  data: function () {
    return {
      links: [
        {
          text: "Agency Care Desk",
          destination: contentSiteConsts.AGENCY_CARE_DESK,
          target: "_self",
        },
        {
          text: "Safelite locations",
          destination: contentSiteConsts.STORE_LOCATOR,
          target: "_blank",
        },
        {
          text: "Safelite.com",
          destination: contentSiteConsts.SAFELITE_HOME,
          target: "_blank",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/navigation/navigation-mixins.scss";
.plain-nav {  
  span {
    color: $secondary;
    font-weight: $font-weight-bold;
  }
  a{
    color: $secondary;
    font-weight:$font-weight-semibold;
  }

  @extend %general-nav;
}

@include wide-mobile {
  .plain-nav {
    > ul {
      padding: 20px 0 0;
      display: flex;
      flex-wrap: wrap;

      > li {
        display: block;
        width: 50%;
        flex: 0 0 auto;

        > a {
          padding: 0 $page-padding 15px;
        }
      }
    }
  }
}

@include tablet {
  .plain-nav {
    a,
    span {
      color: $secondary;
      line-height: 25px;
      text-decoration: none;
      text-decoration: underline rgba($blue-dark, 0);
      display: block;
      margin-bottom: 15px;

      &:hover {
        color: map-get($theme-darks, secondary);
        text-decoration: underline;
        text-decoration: underline rgba($blue-dark, 1);
      }
    }
  }
}
</style>