<template>
<li class="item" v-if="link" @click="navigateToRoute(link.navigationScenario)">
    <button>{{ link.text }}</button>
</li>
</template>

<script>
export default {
    name: "standard-header-main-navigation-item",
    props: {
        link: Object,
    },
    emits: ['item-clicked'],
    methods: {
        navigateToRoute(scenario) {
            this.$emit("item-clicked");
            this.$router?.navigate(scenario, {});
        }
    }
};
</script>

<style lang="scss" scoped>
 
li.item {
    display: block;
    transition: $transition-length all linear $transition-length * 0.2;
    font-weight: 400;

    button {
        @include a-text;
        background-color: transparent;
        color: $grayscale-two;
        line-height: 26px;
        font-weight: $font-weight-regular;
    }
   
}

@include tablet {
    .item {
        margin: 0 $spacer-five;
        border-bottom: 5px solid rgba($red, 0);

        &:last-child {
            margin-right: 0px;
        }

        button {
            padding: $spacer-five 0px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .item:hover {
        border-bottom-color: rgba($red, 1);
        transition: $transition-length all linear;
        cursor: pointer;
    }
}

@media (min-width: $break-point-tablet + 1) and (max-width: $break-point-desktop - 1) {
    .item {
        margin: 0 $spacer-three;
    }
}

@include wide-mobile {
    .item {
        height: 66px;
        padding: $spacer-five $spacer-four;
        border-bottom: 1px solid $grayscale-five;
    }
}
</style>
