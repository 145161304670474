<template>
    <header class="site-header-wrapper">
        <div class="site-header" ref="siteHeader">
            <div style="width:0px">
                <StandardHeaderLogoImage @click.native="handleLogoClick"
                :isCobrandedAgent="isCobrandedAgent"/>
        </div>
            <div class="site-nav" v-if="!shouldHidePrimaryNav">
                <div class="primary-nav">
                    <StandardHeaderChatButton :isSafeliteAgent="isSafeliteAgent" v-if="!shouldHideChatButton" @chat-button-clicked="chatButtonClicked" :isChatEnabled="isChatEnabled" />
                    <StandardHeaderHamburgerMenu @hamburger-menu-toggled="hamburgerMenuToggled()" />
                    <StandardHeaderUserMenu :initialTop="mobileNavHeight" :isExploreTopicsMenuOpen="isExploreTopicsMenuOpen" @user-menu-link-clicked="closeMenus()" :isHamburgerMenuOpen="this.isHamburgerMenuOpen" />
                </div>
                <StandardHeaderNavContainer ref="standardHeaderNavContainer" :initialIsOpen="isExploreTopicsMenuOpen" @explore-topics-menu-expanded="isExploreTopicsMenuOpen = $event" @item-clicked="closeMenus()" :shouldHideFileClaimButton="shouldHideFileClaimButton" @click.self="hamburgerMenuToggled(true)" :navigation="navigation" />
            </div>
            <div class="progress-bar-container" v-if="shouldShowProgressBar">
                <StandardHeaderChatButton v-if="!shouldHideChatButton" @chat-button-clicked="chatButtonClicked" :isChatEnabled="isChatEnabled" />
                <StandardHeaderProgressBar :progress="progress" />
            </div>
            <ModalWithButtons  primaryButtonText="Yes, leave" secondaryButtonText="No, stay here" :openModal="isCancelModalOpen" @closing="showCancelModal(false, 'close')" @secondary-button-clicked="showCancelModal(false)" @primary-button-clicked="onCancelContinueClicked">
            <h4 class="cancel-title">Are you sure you want to leave this page?</h4>
            <p class="cancel-content pb-6">If you leave now, you won't be able to finish registering for this course unless you click the link again in the email sent to you.</p>
        </ModalWithButtons>
        </div>
        <div v-if="shouldShowProgressBar || shouldHidePrimaryNav" class="agency-name-banner-container">
            <div class="agency-name-banner d-flex">
                <div class="agency-name-banner-text">
                    {{agencyName}}
                </div>
            </div>
        </div>
    </header>
</template>
    
    <script>
    import ModalWithButtons from "@/sharedcomponents/ModalWithButtons.vue";
    import StandardHeaderLogoImage from "@/sharedcomponents/StandardHeaderLogoImage.vue";
    import StandardHeaderNavContainer from "./StandardHeaderNavContainer/StandardHeaderNavContainer.vue";
    import StandardHeaderHamburgerMenu from "./StandardHeaderHamburgerMenu/StandardHeaderHamburgerMenu.vue";
    import StandardHeaderChatButton from "./StandardHeaderChatButton/StandardHeaderChatButton.vue";
    import StandardHeaderUserMenu from "./StandardHeaderUserMenu/StandardHeaderUserMenu.vue";
    import StandardHeaderProgressBar from "./StandardHeaderProgressBar.vue";
    import { useUserStore } from "@/stores/user.js"	
    import { useSfaStore } from "@/stores/sfa.js"	
    import { useAgencyCarrierStore } from "@/stores/agencycarrier.js"

    import {
        EventBus
    } from "@/event-bus.js";
    import {
        navigationScenarioConstants,
        routeNameConstants,
        globalEventConsts,
        OIDC
    } from "@/constants";
    import {
        dashboardRoutes
    } from "@/router/routes.js";
    import Vue from 'vue';

    export default {
        name: "standard-header",
        setup() {
            const userStore = useUserStore();
            const sfaStore = useSfaStore();
            const agencyCarrierStore = useAgencyCarrierStore();
            return { userStore, sfaStore, agencyCarrierStore };
        },
        components: {
            StandardHeaderLogoImage,
            StandardHeaderNavContainer,
            StandardHeaderHamburgerMenu,
            StandardHeaderChatButton,
            StandardHeaderUserMenu,
            StandardHeaderProgressBar,
            ModalWithButtons,
        },
        data() {
            return {
                isCancelModalOpen: false,
                shouldShowLogo: false,
                mobileNavHeight: 0,
                isHamburgerMenuOpen: false,
                navigation: [{
                        text: "My dashboard",
                        navigationScenario: navigationScenarioConstants.NAVIGATE_TO_AGENT_DASHBOARD
                    },
                    {
                        text: "Agency Care Desk",
                        navigationScenario: navigationScenarioConstants.NAVIGATE_TO_AGENCY_CARE_DESK
                    },
                    {
                         text: "CE courses",
                         navigationScenario: navigationScenarioConstants.NAVIGATE_TO_CE_COURSES_HOME
                    }
                ],
                coBrandedLogoAltName: '',
                cobrandedAccountNumber: '',
                coBrandedLogoAltName: '',
                userId: '',
                isSafeliteAgent: false,
                isExploreTopicsMenuOpen: false,
                progress: 0,
            }
        },
        computed: {
            shouldHidePrimaryNav() {
                const pagesWherePrimaryNavIsHidden = [
                    routeNameConstants.FIND_AGENCY_PAGE.toLowerCase(),
                    routeNameConstants.AGENT_REGISTRATION.toLowerCase(),
                    routeNameConstants.SELECT_AGENCY_PAGE.toLowerCase(),
                    routeNameConstants.AGENCY_NOT_FOUND_PAGE.toLowerCase(),
                    routeNameConstants.AGENT_REGISTRATION_SUCCESSFUL.toLowerCase(),
                    routeNameConstants.COURSE_REGISTRATION_UNAUTH.toLowerCase(),
                    routeNameConstants.POLICY_HOLDER_QUOTE.toLowerCase(),
                    this.userStore?.userRole?.includes(OIDC.INTERNAL_ADMIN_ROLE) ? routeNameConstants.POLICY_HOLDER_QUOTE.toLowerCase() : routeNameConstants.START_CLAIM.toLowerCase(),
                    this.userStore?.userRole?.includes(OIDC.INTERNAL_ADMIN_ROLE) ? routeNameConstants.POLICY_HOLDER_QUOTE.toLowerCase() : routeNameConstants.MANAGE_CLAIM.toLowerCase(),
                    routeNameConstants.APPOINTMENT_OPTION_DETAILS.toLowerCase(),
                    routeNameConstants.SUBMIT_CLAIM_REVIEW_DETAILS.toLowerCase(),
                    routeNameConstants.SUCCESS_SUBMIT_CLAIM.toLowerCase(),
                ]
    
                const routeName = this.$route?.name;
                return routeName && pagesWherePrimaryNavIsHidden.indexOf(routeName.toLowerCase()) != -1 ? true : false;
    
            },
            userLogappDetails(){
                return this.authState.idToken ? this.authState.idToken.claims.email : 'Unknown';
            },
            shouldHideFileClaimButton() {
                const idToken = this.authState?.idToken;
                return idToken && idToken.claims.dashboardUserRole && (idToken.claims.dashboardUserRole?.includes(OIDC.INTERNAL_ADMIN_ROLE) || idToken.claims.dashboardUserRole?.includes(OIDC.CE_ADMIN_ROLE));
            },
            shouldHideChatButton() {
                const isDashboardRoute = dashboardRoutes.filter(route => route.path === this.$route?.path).length > 0;
                if (isDashboardRoute) {
                    const pagesWherePrimaryNavIsHidden = [
                        routeNameConstants.LOGIN_SCREEN.toLowerCase()
                    ]
    
                    return this.$route?.name != null && pagesWherePrimaryNavIsHidden.indexOf(this.$route?.name.toLowerCase()) != -1;
                } else {
                    return !this.authState?.idToken;
                }
            },
            shouldShowProgressBar() {
                const pagesWherePrimaryNavIsHidden = [
                    routeNameConstants.POLICY_HOLDER_QUOTE.toLowerCase(),
                    routeNameConstants.APPOINTMENT_OPTION_DETAILS.toLowerCase(),
                    routeNameConstants.SUBMIT_CLAIM_REVIEW_DETAILS.toLowerCase(),
                    this.userStore?.userRole?.includes(OIDC.INTERNAL_ADMIN_ROLE) ? routeNameConstants.POLICY_HOLDER_QUOTE : routeNameConstants.START_CLAIM.toLowerCase(),
                    this.userStore?.userRole?.includes(OIDC.INTERNAL_ADMIN_ROLE) ? routeNameConstants.POLICY_HOLDER_QUOTE : routeNameConstants.MANAGE_CLAIM.toLowerCase(),
    
                ]
                if (this.sfaStore.isCobranded) {
                    pagesWherePrimaryNavIsHidden.splice(1, 1);
                }
    
                return this.$route?.name && pagesWherePrimaryNavIsHidden.indexOf(this.$route?.name.toLowerCase()) != -1 ? true : false;
            },
            agencyName() {
                //Pull agency name from the store data for the user's agency list.
                //NOTE: The agency name should only show for the file a claim and get a quote flows - the progress bar only shows on these pages,
                //so we are using showProgressBar and shouldHidePrimaryNav to handle.
                return this.userStore?.agencyList?.filter(a => a.agencyNumber === this.userStore?.activeAgencyNumber)[0]?.agencyName;
            },
            isChatEnabled(){
                return this.sfaStore?.webChatEnabled;
            },
            isCobrandedAgent() {
                return this.sfaStore.isCobranded;
            },
        },
        watch: {
           "authState.idToken": function (newIdToken) {
                if (newIdToken) {
                    this.isSafeliteAgent = newIdToken.claims.dashboardUserRole?.includes(OIDC.AGENT_USER_ROLE);
                    this.userId = newIdToken.claims.sub;
                    this.getUserDetails();
                }
            }
        },
        methods: {
            closeMenus() {
                this.hamburgerMenuToggled(true);
            },
            showAreYouSureModal() {
                var courseID = sessionStorage.getItem("courseID");
                if (courseID != null) {
                    if(this.$route?.name==routeNameConstants.COURSE_REGISTRATION_UNAUTH 
                        || this.$route?.name==routeNameConstants.SELECT_AGENCY_PAGE
                        ||this.$route?.name==routeNameConstants.FIND_AGENCY_PAGE
                        || this.$route?.name == routeNameConstants.AGENT_REGISTRATION
                        || this.$route?.name == routeNameConstants.LOGIN_SCREEN) {
                            return true;
                        }
                    }
                else {
                    return false;
                }
            },
            hamburgerMenuToggled(shouldBeOff) {
                this.mobileNavHeight = this.$refs.siteHeader?.offsetHeight + this.$refs.standardHeaderNavContainer.$refs.headerMainNav?.offsetHeight + 1;
                if (shouldBeOff) {
                    this.isHamburgerMenuOpen = false;
                    document.documentElement.classList.remove("nav-open");
                } else {
                    this.isHamburgerMenuOpen = !this.isHamburgerMenuOpen;
                    document.documentElement.classList.toggle("nav-open");
                }
            },
            handleLogoClick() {
               if(this.showAreYouSureModal() == true)
                {
                    this.showCancelModal(true);
                }  else {
                    sessionStorage.removeItem("courseID");
                    sessionStorage.removeItem("OktaID");
                    this.$router.navigate(
                    navigationScenarioConstants.NAVIGATE_TO_CONTENT_HUB_HOME, {}
                );
                }
                
            },
            chatButtonClicked() {
                this.openWebChat(this.authState.idToken ? this.authState.idToken.claims : null);
            },
            showCancelModal(shouldShowCancelModal, btnLabel = null) {
                this.isCancelModalOpen = shouldShowCancelModal;
            },
            onCancelContinueClicked() {
               var self = this;
    
                self.$router.navigate(
                    navigationScenarioConstants.NAVIGATE_TO_CONTENT_HUB_HOME, {}
                );
                sessionStorage.removeItem("courseID");
                sessionStorage.removeItem("OktaID");
                this.showCancelModal(false);
    
            },
            openWebChat(Userinfo) {
                this.pushEventToGoogleDataLayer("web_chat", "chat_accept", "insurance_agent_started_chat", 0);
                EventBus.$emit(globalEventConsts.OPEN_CHAT, Userinfo);
            },
            //This method is called once we have an id token (watch). This will invoke getCobrandedInfo which will lead
            //to the logo getting set.
            getUserDetails() {
                let self = this;
                if (self.userStore?.userRole != OIDC.INTERNAL_ADMIN_ROLE) {
                    self.userStore.getUserDetailsAsync({
                        userId: self.userId,
                        blocking: true
                    })
                    .then(function (response) {
                        if (response.data.Accounts?.length == 1) {
                            self.agencyCarrierStore.accountTPP.SFA_TPP_FLAG = response.data.Accounts[0].SFA_TPP_FLAG;
                            //Check the parent account for the logged in user - if cobranded override the logo as it may be incorrect.
                            var parentAcctNum = response.data.AgencyInformation.ParentAccountNumber
                                            var priorityCode = response.data.AgencyInformation.PRIORITY
                                                        
                            //If the agency is dedicated and has a parent acct num, check if they're cobranded and handle the logo if necessary.
                            if(parentAcctNum > 0 && priorityCode == "D") {
                                self.sfaStore.setLogoByAccount({accountNumber: parentAcctNum, setDefault: true, blocking: true})
                            }
                        }
                    });
                }
            },
        },
        mounted() {
            if (!this.sfaStore.clientTagInfo.tagId || this.sfaStore.clientTagInfo.tagId == "") {
                this.sfaStore.defaultLogo = {...this.sfaStore.safeliteLogo};
                this.sfaStore.activeLogo = {...this.sfaStore.safeliteLogo};
            }
            //Event registrations
            EventBus.$on(globalEventConsts.UPDATE_PROGRESS_BAR, newValue => {
                this.progress = newValue;
            });
        },
    };
    </script>
    
    <style lang="scss">
    @import "@/styles/navigation/chevron.scss";
    @import "@/styles/navigation/navigation-mixins.scss";
    
    .site-header-wrapper {
        z-index: 50;
        border-bottom: $divider-primary;
    
        .invisible {
            visibility: hidden;
        }
    
        .site-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 3px;
            max-width: $page-width-padded;
            margin: 23px auto 0 auto;
    
            .standard-header-logo-image {
                img {
                    max-height: 100% !important;
                }
            }
    
            .progress-bar-container {
                display: flex;
                height: 100%;
                justify-content: flex-end;                
            }
        }
    
        .site-nav {
            ul {
                list-style: none;
                background-color: $grayscale-eight;
                line-height: 25px;
            }
    
            a,
            span {
                color: $grayscale-two;
                text-decoration: none;
                display: block;
            }
        }
    }
    
    .agency-name-banner-container {
        font-family: $font-family-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: $font-weight-regular;
        line-height: 26px; /* 162.5% */
        background-color: $grayscale-seven;
        border-top:$divider-primary;
        
        word-wrap: break-word;
    
       
    .agency-name-banner {
        max-width: $page-width-padded;
        padding: $spacer-three $page-padding;
        margin: auto;
        align-items: center;
       

        .agency-name-banner-text {
            text-align: left;
            flex-grow: 1;
            color: #525656;
        }
    }
}



    
    @include wide-mobile {
        .site-header-wrapper {
            .site-header {
                padding: 0 $spacer-four;
                height: 55px;
                margin: auto;
    
                .site-nav,
                .primary-nav {
                    z-index: 102;
                    display: flex;
                    align-items: center;
                }
    
                picture {
                    margin: auto 0;
                }
    
                .progress-bar-container {
                    flex-direction: row-reverse;
                }
                
            }
        }
        .standard-header-logo-image {
                img {
                    max-height: 65% !important;
                }
            }
    }
    
    @include tablet {
        .site-header {
            height: 100px;
    
            .site-nav {
                align-self: flex-end;
    
                .primary-nav {
                    display: flex;
                    justify-content: flex-end;
                }
            }
    
            .progress-bar-container {
                flex-direction: column;
                
            }
        }
    }
    </style>
    